/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Stack, Paper, Button, Typography } from '@mui/material';
import MuiLink from '@mui/material/Link';
import Crypto from '#rc/Yeti'
import { Plans, Motivation } from '#rc/Plans'
import { CustomPlan } from '#rc/CustomPlan'
import FastRegister from '#rc/FastRegister'
import PromoCode from '#rc/PromoCode'
import LegalInfo from '#rc/LegalInfo'
// import { PayPal } from '../components/Paypal'
// state
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  accountState,
  tokenState,
  plansState,
  customPlanMonthsState,
  selectedPlanIDState,
  showPaddleLoaderState,
  promoCodeState,
  couponState,
  isSubscribedSelector,
  initPlanIdState,
  clickIdState,
} from '#state'
// import moneyBackBadge from './assets/money-back-badge-small.svg'
// import './presale.css'
// import './purchase.css'
const Paddle = window.Paddle

export default function PlansPage(props) {
  const location = useLocation()
  const navigate = useNavigate()

  const account = useRecoilValue(accountState)
  const token = useRecoilValue(tokenState)
  const plans = useRecoilValue(plansState)
  const [selectedPlanID, setSelectedPlanID] = useRecoilState(selectedPlanIDState)
  const [showPaddleLoader, setShowPaddleLoader] = useRecoilState(showPaddleLoaderState)
  const [initPlanId, setInitPlanId] = useRecoilState(initPlanIdState)
  const isSubscribed = useRecoilValue(isSubscribedSelector)

  const selectedPlan =
    plans?.common.find(plan => {
      const pid = selectedPlanID || initPlanId
      return plan.id === pid
    }) || plans?.custom

  return (
    <main>
      <Motivation />

      <PurchaseStep>1. Select your subscription</PurchaseStep>

      <Plans
        showBest
        plans={plans}
        selectedPlanID={selectedPlanID}
        setSelectedPlanID={setSelectedPlanID}
      />

      <CustomPlan
        plans={plans}
        selectedPlanID={selectedPlanID}
        setSelectedPlanID={setSelectedPlanID}
        selectedPlan={selectedPlan}
      />

      <PurchaseStep>
        2. Create or login your Dingo Account
      </PurchaseStep>

      <FastRegister />

      <PurchaseStep>
        3. Enter your promo code
      </PurchaseStep>

      <PromoCode />

      <PurchaseStep>
        4. Select a payment method
      </PurchaseStep>

      <SubOrLogin
        plans={plans}
        selectedPlan={selectedPlan}
        selectedPlanID={selectedPlanID}
        account={account}
        token={token}
        showPaddleLoader={showPaddleLoader}
        setShowPaddleLoader={setShowPaddleLoader}
      />
    </main>
  )
}

// function StepperBlock(props) {

//   return (<Box sx={{ mt: 2 }}>
//     <Stepper
//       activeStep={activeStep}
//       orientation="vertical">

//       <Step key={'1'}>
//         <StepLabel>
//           1. Choose your subscription plan
//         </StepLabel>

//         <StepContent>


//           <Plans
//             showBest
//             plans={plans}
//             selectedPlanID={selectedPlanID}
//             setSelectedPlanID={setSelectedPlanID}
//           />

//           <CustomPlan
//             plans={plans}
//             selectedPlanID={selectedPlanID}
//             setSelectedPlanID={setSelectedPlanID}
//             selectedPlan={selectedPlan}
//             setPurchaseMonths={setPurchaseMonths}
//           />
//           <Button
//             variant="contained"
//             onClick={handleNext}
//             sx={{ mt: 1, mr: 1 }}
//           >
//             Continue
//           </Button>
//         </StepContent>
//       </Step>

//       <Step key={'2'}>
//         <StepLabel
//           optional={
//             <Typography variant="caption">
//               or login with your existing account
//             </Typography>}
//         >
//           2. Create your Dingo account
//         </StepLabel>

//         <StepContent>
//           <FastRegister />
//           <Box sx={{ mb: 2 }}>
//             <div>
//               <Button
//                 onClick={handleBack}
//                 sx={{ mt: 1, mr: 1 }}
//               >
//                 Back
//               </Button>
//               <Button
//                 variant="contained"
//                 onClick={handleNext}
//                 sx={{ mt: 1, mr: 1 }}
//               >
//                 Continue
//               </Button>

//             </div>
//           </Box>
//         </StepContent>
//       </Step>

//       <Step key={'3'}>
//         <StepLabel
//           optional={
//             <Typography variant="caption">
//               Last step
//             </Typography>}
//         >
//           3. Select a payment method
//         </StepLabel>

//         <StepContent>
//           <SubOrLogin
//             plans={plans}
//             selectedPlan={selectedPlan}
//             selectedPlanID={selectedPlanID}
//             account={account}
//             token={token}
//             showPaddleLoader={showPaddleLoader}
//             setShowPaddleLoader={setShowPaddleLoader}
//           />
//           <Button
//             onClick={handleBack}
//             sx={{ mt: 1, mr: 1 }}
//           >
//             Back
//           </Button>
//         </StepContent>
//       </Step>

//     </Stepper>
//   </Box>)
// }

function PurchaseStep({ children }) {
  return (
    <Box >
      <Typography
        variant="body1"
        color="text.primary"
        sx={{
          mt: 2,
          pr: 0.5,
          display: 'inline-block',
          borderBottom: '1px solid',
          borderColor: 'text.primary',
        }}
      >
        {children}
      </Typography>
    </Box>
  )
}

function SubOrLogin(props) {
  const {
    selectedPlanID,
    selectedPlan,
    account,
    token,
    plans,
    showPaddleLoader,
    setShowPaddleLoader
  } = props

  const [customPlanMonths, setCustomPlanMonths] = useRecoilState(customPlanMonthsState)
  const coupon = useRecoilValue(couponState)
  const promoCode = useRecoilValue(promoCodeState)
  const clickId = useRecoilValue(clickIdState)
  const [buttonText, setButtonText] = useState('Pay with Card')
  const [productInfo, setProductInfo] = useState(null)

  useEffect(() => {
    if (!plans || !selectedPlanID) return () => { }
    let productPaddle
    if (selectedPlanID === plans.custom.id) {
      productPaddle = selectedPlan.providers.paddle[customPlanMonths - 1]
    } else {
      productPaddle = selectedPlan.providers.paddle
    }
    // console.log('productPaddle', productPaddle)
    setProductInfo(productPaddle)

    if (!productPaddle) {
      setButtonText('Card Payment Unavailable')
    } else {
      setButtonText('Pay with Card')
    }
  }, [plans, selectedPlanID, customPlanMonths])
  // useEffect(() => {
  //   if (account && plans) {
  //     const selectedPlan = plans.common.findIndex(plan => plan.id === selectedPlanID) || plans.custom
  //     const product = selectedPlan.providers?.paddle?.id || 22362

  //     Paddle.Checkout.open({
  //       method: 'inline',
  //       product,
  //       email: account.email,
  //       allowQuantity: false,
  //       disableLogout: true,
  //       frameTarget: 'checkout-container', // The className of your checkout <div>
  //       frameInitialHeight: 416,
  //       frameStyle: 'width:100%; min-width:312px; background-color: transparent; border: none;'    // Please ensure the minimum width is kept at or above 286px with checkout padding disabled, or 312px with checkout padding enabled. See "General" section under "Branded Inline Checkout" below for more information on checkout padding.
  //     })
  //   }
  // }, [plans, selectedPlanID])

  if (!plans || !selectedPlanID) return null

  let priceResult = 0
  if (plans && selectedPlan && customPlanMonths) {
    priceResult = selectedPlan.priceResult
    if (selectedPlanID === plans.custom.id) {
      let savePercent = 0
      for (let i = 0; i < customPlanMonths; i++) {
        savePercent += selectedPlan.monthSave[i]
      }
      priceResult =
        (selectedPlan.priceMonthUSD * customPlanMonths * (100 - savePercent)) / 100

      priceResult = `$${priceResult.toFixed(2)}`
    }
  }

  if (account) {
    const { id, email, paymentInfo } = account

    return (
      <>
        <Box className=''>
          <Box className='paddle-payment'>
            {/* <div className='checkout-container'></div> */}
            <Button
              fullWidth
              variant="outlined"
              color="inherit"
              sx={{ mt: 2 }}
              onClick={() => {
                if (!productInfo) {
                  return null
                }
                const product = productInfo.id
                console.log('Paddle productID', product, productInfo)
                // Paddle.Spinner.show()
                setShowPaddleLoader(true)
                Paddle.Checkout.open({
                  product,
                  email,
                  coupon,
                  passthrough: JSON.stringify({
                    id,
                    clickId,
                    promoCode,
                    coupon,
                  })
                })
                if (window.gtag) {
                  window.gtag('event', 'conversion', { 'send_to': 'AW-10986044252/DGaiCNnJp5oYENz2xvYo' });
                  window.gtag('event', 'begin_checkout', {
                    'event_callback': () => {
                      console.log('begin_checkout')
                    }
                  });
                }
              }}
            >
              <PaddleContent
                showPaddleLoader={showPaddleLoader}
                buttonText={buttonText}
              />
            </Button>
            {/* <div className='text-micro font-medium text-grey-dark payment-price'>
              {priceResult}
            </div> */}
          </Box>
          {/* <Box className='crypto-payment'>
            <Crypto
              plans={plans}
              selectedPlanID={selectedPlanID}
              selectedPlan={selectedPlan}
              account={account}
              token={token}
            />
          </Box> */}
        </Box>
        <LegalInfo />
      </>
    )
  } else {
    // return <LoginOrRegister />
    return (<>
      <Button
        fullWidth
        disabled
        variant="outlined"
        color="inherit"
        sx={{ mt: 2 }}
      >
        {buttonText}
      </Button>
      <LegalInfo />
    </>)
  }
}

function PaddleContent(props) {
  const { showPaddleLoader, buttonText } = props
  if (!showPaddleLoader) return <>{buttonText}</>

  return (
    <>
      <Box>Loading payment system <CircularProgress size={18} /></Box>
    </>
  )
}

import React from 'react'
import Box from '@mui/material/Box'
import 'flag-icons'

export default function Flag (props) {
  const { country, size } = props
  return (
    <Box
      className={`fi fi-${country.toLowerCase()}`}
      sx={{ height: size, width: size * 1.33333 }}
    />
  )
}

import * as React from 'react';
import Stack from '@mui/material/Stack';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiLink from '@mui/material/Link';
import Box from '@mui/material/Box';

export default function LegalInfo() {
  return (
    <Box sx={{ mb: 1, mt: 2 }}>
      <Accordion
        sx={{
          backgroundColor: 'transparent',
          backgroundImage: 'none',
          justifyContent: 'flex-start',
          boxShadow: 'none',
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="LegalInfo-panel-content"
          id="LegalInfo-panel-header"
          sx={{ p: 0, }}
        >
          <Typography variant="body2" sx={{}}>
            Subscription, Automatic Renewal, and other Terms
          </Typography>
        </AccordionSummary>

        <AccordionDetails sx={{ p: 0 }}>
          <Stack
            direction={'column'}
            spacing={1}
          >

            <Stack direction={{
              xs: 'column',
              sm: 'row',
              justifyContent: 'space-between',
              mt: 2,
            }}>
              <Typography sx={{ minWidth: '8rem' }}>
                Subscription:
              </Typography>
              <Typography>
                Your subscription begins when your purchase is completed (or otherwise, as soon as we've received your payment).
              </Typography>
            </Stack>

            <Stack direction={{
              xs: 'column',
              sm: 'row',
              justifyContent: 'space-between',
              mt: 2,
            }}>
              <Typography sx={{ minWidth: '8rem' }}>
                Renewal:
              </Typography>
              <Typography>
                Your subscription will be automatically renewed after the end of your initial subscription period.
              </Typography>
            </Stack>

            <Stack direction={{
              xs: 'column',
              sm: 'row',
              justifyContent: 'space-between',
              mt: 2,
            }}>
              <Typography sx={{ minWidth: '8rem' }}>
                Renewal price:
              </Typography>
              <Typography>
                The next subscription period will be billed at the applicable monthly or annual renewal price, which is subject to change. We will always notify you by email in advance.
              </Typography>
            </Stack>

            <Stack direction={{
              xs: 'column',
              sm: 'row',
              justifyContent: 'space-between',
              mt: 2,
            }}>
              <Typography sx={{ minWidth: '8rem' }}>
                Cancellation:
              </Typography>
              <Typography>
                You can cancel your subscription by contacting our{' '}
                <MuiLink
                  target='_blank'
                  rel='noopener noreferrer'
                  href="mailto:contact@dingovpn.com"
                >
                  Customer Support
                </MuiLink>
                {' '}team.
              </Typography>
            </Stack>

            <Stack direction={{
              xs: 'column',
              sm: 'row',
              justifyContent: 'space-between',
              mt: 2,
            }}>
              <Typography sx={{ minWidth: '8rem' }}>
                Refund:
              </Typography>
              <Typography>
                You can get a refund by contacting our
                {' '}
                <MuiLink
                  target='_blank'
                  rel='noopener noreferrer'
                  href="mailto:contact@dingovpn.com"
                >
                  Customer Support
                </MuiLink>
                {' '} team within 30 days of your initial purchase. Check out our{' '}
                <MuiLink
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://dingovpn.com/terms.html'
                >
                  {'Refund Policy'}
                </MuiLink>
                {' '}for more details.
              </Typography>
            </Stack>

            <Stack direction={{
              xs: 'column',
              sm: 'row',
              justifyContent: 'space-between',
              mt: 2,
            }}>
              <Typography sx={{ minWidth: '8rem' }}>
                Policies:
              </Typography>
              <Typography>
                By purchasing a DingoVPN subscription, you agree to our{' '}
                <MuiLink
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://dingovpn.com/terms.html'
                >
                  {'Terms of Service'}
                </MuiLink>. You can find out how we collect, store, and share your data in our{' '}
                <MuiLink
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://dingovpn.com/privacy.html'
                >
                  {'Privacy Policy'}
                </MuiLink>.
              </Typography>
            </Stack>

          </Stack>
        </AccordionDetails>
      </Accordion>
    </Box>

  );
}
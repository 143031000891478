import * as React from 'react';
import Stack from '@mui/material/Stack';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiLink from '@mui/material/Link';
import Box from '@mui/material/Box';

export default function PartnerLegalInfo() {
  return (
    <Box sx={{ mb: 1, mt: 2 }}>
      <Accordion
        sx={{
          backgroundColor: 'transparent',
          backgroundImage: 'none',
          justifyContent: 'flex-start',
          boxShadow: 'none',
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="LegalInfo-panel-content"
          id="LegalInfo-panel-header"
          sx={{ p: 0, }}
        >
          <Typography variant="body2" sx={{}}>
            DingoVPN Business, offers, and other Terms
          </Typography>
        </AccordionSummary>

        <AccordionDetails sx={{ p: 0 }}>
          <Stack
            direction={'column'}
            spacing={1}
          >
            <Stack direction={{
              xs: 'column',
              sm: 'row',
              justifyContent: 'space-between',
              mt: 2,
            }}>
              <Typography sx={{ minWidth: '8rem' }}>
                Policies:
              </Typography>
              <Typography>
                By purchasing a DingoVPN Business plan you agree to our{' '}
                <MuiLink
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://dingovpn.com/terms.html'
                >
                  {'Terms of Service'}
                </MuiLink>. You can find out how we collect, store, and share your data in our{' '}
                <MuiLink
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://dingovpn.com/privacy.html'
                >
                  {'Privacy Policy'}
                </MuiLink>.
              </Typography>
            </Stack>

          </Stack>
        </AccordionDetails>
      </Accordion>
    </Box>

  );
}
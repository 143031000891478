import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { motion, AnimatePresence } from 'framer-motion'
import localforage from 'localforage'
import { useTranslation } from 'react-i18next'
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import CircularProgress from '@mui/material/CircularProgress';
import { nanoid } from 'nanoid'
// 
import { Field, SecureField, Awaiter } from '#page/Auth/components'
import * as requests from '#src/requests'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  accountState,
  tokenState,
  accountEmailState,
  showAuthAwaiterState,
  clickIdState,
  partnerSessionState
} from '#state'

const emailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

export default function FastRegister(props) {
  const { business, promoId } = props
  const { t } = useTranslation()
  const theme = useTheme()

  const [email, setEmail] = useRecoilState(accountEmailState)
  const setToken = useSetRecoilState(tokenState)
  const [account, setAccount] = useRecoilState(accountState)
  const [showAuthAwaiter, setShowAuthAwaiter] = useRecoilState(showAuthAwaiterState)
  const clickId = useRecoilValue(clickIdState)
  // partner
  const partnerSession = useRecoilValue(partnerSessionState)
  // const returnURL = partnerSession?.successUrl || null

  const [pass, setPass] = useState('')
  const [loginError, setLoginError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [showErrors, setShowErrors] = useState(false)
  const [isAwaiting, setIsAwaiting] = useState(false)
  const [autoregAllowed, setAutoregAllowed] = useState(true)

  useEffect(() => {
    if (email === '') {
      const errMessage = t('account.errors.emptyEmail')
      setLoginError(errMessage)
    } else if (!email.match(emailformat)) {
      const errMessage = t('account.errors.incorrectEmail')
      setLoginError(errMessage)
    } else {
      setLoginError('')
    }
    // check password
    if (pass === '') {
      const errMessage = t('account.errors.emptyPass')
      setPasswordError(errMessage)
    } else {
      setPasswordError('')
    }
  }, [email, pass])

  useEffect(() => {
    let timeout
    if (!account && partnerSession?.email && autoregAllowed) {
      const autopass = `${nanoid()}${nanoid()}`
      // console.log('FastRegister trigger ok', autopass)
      timeout = setTimeout(() => {
        // console.log('FastRegister trigger timeout', autopass)
        setPasswordError('')
        setPass(autopass)
      }, 300);
    }
    // console.log('FastRegister partnerSession', partnerSession)

    return () => {
      clearTimeout(timeout)
    }
  }, [account, partnerSession, autoregAllowed])

  useEffect(() => {
    let timeout
    if (!account && partnerSession?.email && email && pass && autoregAllowed) {
      // console.log('FastRegister trigger check', account, partnerSession?.email, email, pass)
      timeout = setTimeout(() => {
        check()
      }, 100);
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [account, partnerSession, email, pass, autoregAllowed])

  const check = async event => {
    if (showAuthAwaiter || isAwaiting) return null
    // app root reload \ rerender fix
    if (event) event?.preventDefault()

    if (loginError || passwordError) {
      console.log('login or pass incorrect', loginError, passwordError)
      setShowErrors(true)
    } else {
      setIsAwaiting(true)
      setShowAuthAwaiter(true)
      let result = await requests.login(email, pass)
      if (!result.success) {
        console.log('login error', result)
        if (result.error === 'get_profile_error') {
          result = await requests.register(email, pass, clickId, business, promoId)
          if (result.success && window.gtag) {
            window.gtag('event', 'conversion', {
              'send_to': 'AW-10986044252/ozRECPrz0ZYYENz2xvYo',
              'event_callback': () => {
                console.log('registration_callback')
              }
            });
            window.gtag('event', 'registration', {
              'event_callback': () => {
                console.log('registration_callback')
              }
            });
          }
        }
      }
      setShowAuthAwaiter(false)
      setIsAwaiting(false)
      if (!result.success) {
        console.log('register error', result)
        if (result.error) {
          setShowErrors(true)
          const errMessage = t(`account.errors.${result.error}`)
          console.log('errMessage', errMessage)
          setPasswordError(errMessage)
        } else {
          setPasswordError('')
        }
      } else {
        setShowErrors(false)
        console.log('register success', result)
        setToken(result.token)
        try {
          await localforage.setItem('token', result.token)
        } catch (err) {
          console.log('save token error', err)
        }
      }
    }
  }

  const logout = async () => {
    if (showAuthAwaiter || isAwaiting) return null
    setAutoregAllowed(false)
    setToken('');
    setAccount(null);
    try {
      await localforage.removeItem('token')
    } catch (err) {
      console.log('removeItem token error', err)
    }
  };

  return (
    <Paper
      elevation={0}
      sx={{
        py: 2,
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}>

      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent="space-evenly"
        alignItems="flex-start"
        spacing={{ xs: 1, sm: 2 }}
        sx={{ mb: account ? 0 : 2 }}
      >
        <Field
          autoComplete='email'
          disabled={!!account || partnerSession?.email}
          value={email}
          setValue={setEmail}
          label={t('account.register.emailLabel')}
          placeholder={t('account.register.emailPlaceholder')}
          errorMessage={loginError}
          showErrors={showErrors}
          inputProps={{
            style: {
              WebkitBoxShadow: `0 0 0 1000px ${theme.palette.background.paper} inset`
            }
          }}
        />
        {!account ? <SecureField
          value={pass}
          setValue={setPass}
          label={t('account.register.passLabel')}
          placeholder={t('account.register.passPlaceholder')}
          type='password'
          autoComplete='current-password'
          errorMessage={passwordError}
          showErrors={showErrors}
          secureTextEntry
        /> : null}
      </Stack>
      {!account ? <Button
        fullWidth
        disabled={showAuthAwaiter || isAwaiting}
        variant='outlined'
        size='large'
        type="submit"
        onClick={check}
      >
        <Typography>
          {showAuthAwaiter ? <CircularProgress size={24} /> : t('account.register.mainBtn')}
        </Typography>
      </Button>
        : <Button
          fullWidth
          disabled={showAuthAwaiter || isAwaiting}
          variant='outlined'
          size='large'
          type="submit"
          onClick={logout}
          sx={{ mt: 2 }}
        >
          <Typography>
            {showAuthAwaiter ? <CircularProgress size={24} /> : t('settings.accountLogout')}
          </Typography>
        </Button>
      }
      {/* <Awaiter isAwaiting={isAwaiting} /> */}
    </Paper>
  )
}


import React from 'react'
import { Container, Typography } from '@mui/material';

export default function SubscriptionActivated(props) {
  return (
    <Container sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2">
        Status
      </Typography>
      <Typography
        id="modal-modal-description"
        textAlign={'center'}
        sx={{ mt: 2 }}
      >
        DingoVPN Premium subscription successfully activated!
      </Typography>
    </Container>
  )
}

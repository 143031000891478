import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { DAppProvider } from '@usedapp/core'
import { RecoilRoot } from 'recoil'
import reportWebVitals from './reportWebVitals'
import App from './App';

// import {
//   OverlayScrollbars,
//   ScrollbarsHidingPlugin,
//   SizeObserverPlugin,
//   ClickScrollPlugin
// } from 'overlayscrollbars';

// styles base
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'overlayscrollbars/overlayscrollbars.css';
import './index.css';

const config = {
  readOnlyChainId: 56,
  readOnlyUrls: {
    1: 'https://mainnet.infura.io/v3/10dc06d1ed0446a7aabb9dc86df0983d',
    56: 'https://bsc-dataseed.binance.org/'
  }
}
const body = document.querySelector('body')
// const bodyScroll = OverlayScrollbars(body, {
//   scrollbars: {
//     theme: 'os-theme-light',
//     // theme: 'os-theme-dark',
//     autoHide: "scroll",
//   },
// });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <DAppProvider config={config}>
      <RecoilRoot>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </RecoilRoot>
    </DAppProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export default {
  "languages": {
    "en": "Anglais",
    "ru": "Russe",
    "de": "Allemand",
    "es": "Espagnol",
    "fr": "Français",
    "ja": "Japonais"
  },
  "hello": {
    "title": "Protégez votre vie privée en un clic",
    "login": "Connexion",
    "register": "Créer un compte Dingo"
  },
  "servers": {
    "search": "Recherche"
  },
  "drawer": {
    "vpn": "VPN",
    "settings": "Paramètres",
    "support": "Soutien"
  },
  "theme": {
    "current": "Thème actuel",
    "system": "Système",
    "dark": "Sombre",
    "light": "Lumière"
  },
  "paywall": {
    "title": "Dingo VPN Premium",
    "upgradeButton": "PASSER À LA VERSION PREMIUM",
    "titleModal": "Abonnez-vous à Premium",
    "subtitleModal": "Accédez à toutes les fonctionnalités premium",
    "subtitles": [
      "Absolument PAS DE PUBLICITÉ",
      "Vitesse de connexion ultra rapide",
      "Cryptage de niveau militaire",
      "Accès à différents pays",
      "Support technique",
      "Toutes les fonctionnalités à venir"
    ],
    "awaiterText": "En attente de la réponse du serveur d'achat",
    "subscribeButton": "COMMENCER L'ESSAI GRATUIT",
    "subscribeButtonNoTrial": "ABONNEZ-VOUS MAINTENANT",
    "trial": "Essai gratuit de 3 jours ensuite",
    "noTrial": "Abonnement renouvelable",
    "terms": "Conditions d'utilisation",
    "privacy": "Politique de confidentialité",
    "restore": "Restaurer l'achat"
  },
  "dialogs": {
    "wireguardInstall": {
      "title": "Installer WireGuard®",
      "text": "DingoVPN utilise des tunnels client officiels WireGuard® pour sécuriser votre connexion.Appuyez sur le bouton ci-dessous et permettez à l'installation de continuer.",
      "button": "Installer"
    },
    "serviceInstall": {
      "title": "Configuration du service DingoVPN",
      "text": "DingoVPN utilise le service d'arrière-plan pour contrôler votre état de connexion.Appuyez sur le bouton ci-dessous et permettez à l'installation de continuer.",
      "button": "Installation"
    },
    "serviceRun": {
      "title": "Exécutez le service DingoVPN",
      "text": "DingoVPN Background Service n'est pas en cours d'exécution.Appuyez sur le bouton ci-dessous pour continuer.",
      "button": "Commencer"
    },
    "serviceRemove": {
      "title": "Supprimer le service DingoVPN",
      "text": "Le service d'arrière-plan DingoVPN doit être installé et en cours d'exécution pour contrôler votre état de connexion.Appuyez sur le bouton ci-dessous pour le supprimer de toute façon.",
      "button": "Retirer"
    },
    "tunnelRemove": {
      "title": "Retirez le tunnel de WireGuard DingoVPN",
      "text": "Le tunnel DingoVPN Wireguard contrôle votre connexion. Appuyez sur le bouton ci-dessous pour s'arrêter et retirer de toute façon.",
      "button": "Retirer le tunnel"
    },
    "accountExitDialog": {
      "title": "Compte de sortie",
      "text": "Êtes-vous sûr de souhaiter quitter votre compte Dingo?",
      "mainButton": "Sortie",
      "cancelButton": "Annuler"
    },
    "updateDialog": {
      "title": "Mise à jour disponible",
      "text": "La mise à jour DingoVPN est prête à installer.Veuillez redémarrer l'application pour continuer.",
      "mainButton": "Redémarrage",
      "cancelButton": "Plus tard"
    }
  },
  "vpn": {
    "title": "Lieu préféré",
    "buttonConnect": "RELIER",
    "buttonDisconnect": "DÉCONNECTER",
    "freeBadge": "LIBRE",
    "premiumBannerLine1": "3 jours gratuits de Dingo VPN Premium",
    "premiumBannerLine2": "Aucune publicité et connexion la plus rapide",
    "securedConnection": "Connexion sécurisée rapide",
    "insecureConnection": "Connexion sans sécurité",
    "VPNStates": [
      "Débranché",
      "De liaison",
      "Lié",
      "Déconnexion",
      "Erreur"
    ]
  },
  "support": {
    "title": "Contrôles et support",
    "wgReinstall": "Installer WireGuard®",
    "wgReinstallDesc": "Boîte de dialogue de configuration ouvre",
    "wgReinstallButton": "Ouvrir",
    "wgReinstallButtonInstalled": "Installée",
    "wgReinstallButtonNotInstalled": "Pas installé",
    "svcReinstall": "Installer le service DingoVPN",
    "svcReinstallDesc": "Open DingoVPN Service Configuration Dialogue",
    "svcReinstallButton": "Ouvrir",
    "svcReinstallButtonInstalled": "Installée",
    "svcReinstallButtonNotInstalled": "Pas installé",
    "svcRun": "Exécutez le service DingoVPN",
    "svcRunDesc": "Open Redémarrer la boîte de dialogue de service DingoVPN",
    "svcRunButton": "Ouvrir",
    "svcRunButtonRunning": "En cours",
    "svcRunButtonStopped": "Arrêté",
    "svcRemove": "Supprimer le service DingoVPN",
    "svcRemoveDesc": "Open DingoVPN Service Supprimer la boîte de dialogue",
    "svcRemoveButton": "Retirer",
    "wgTunnelRemove": "Arrêtez le tunnel",
    "wgTunnelRemoveDesc": "Faites-le en cas de problèmes de connexion",
    "wgTunnelRemoveButton": "Retirer",
    "wgTunnelRemoveButtonRunning": "En cours",
    "wgTunnelRemoveButtonStopped": "Arrêté"
  },
  "settings": {
    "title": "Réglages",
    "language": "Langue",
    "theme": "Thème",
    "themeDesc": "Modifier l'apparence de l'application",
    "account": "Compte Dingo",
    "accountControl": "Contrôle du compte",
    "accountControlDesc": "Page de contrôle du compte ouvert",
    "accountControlButton": "Ouvrir",
    "accountLogin": "Connexion",
    "accountLogout": "Se déconnecter",
    "accountRemove": "Supprimer le compte",
    "subsControl": "Contrôle des abonnements",
    "startWithSystem": "Commencez par le système",
    "startWithSystemDesc": "Commencez DingoVPN avec le démarrage du système",
    "startHidden": "Commencer à cacher",
    "startHiddenDesc": "Commencer dingovpn minimisé",
    "tracking": "Envoyer des données d'utilisation anonymes",
    "trackingDesc": "Permettez-nous d'améliorer notre application"
  },
  "account": {
    "title": "Compte Dingo",
    "errors": {
      "invalid_password": "Email ou mot de passe incorrect. Réessayer",
      "invalid_code": "Code incorrect. Réessayer",
      "get_profile_error": "Email ou mot de passe incorrect. Réessayer",
      "emptyEmail": "L'e-mail ne peut pas être vide !",
      "incorrectEmail": "Adresse e-mail incorrecte!",
      "emptyPass": "Le mot de passe ne peut pas être vide !",
      "emptyCode": "Le code ne peut pas être vide !"
    },
    "login": {
      "title": "Connexion",
      "createPre": "Nouvel utilisateur?",
      "createLink": "Créer un compte Yéti",
      "emailLabel": "Adresse e-mail",
      "emailPlaceholder": "dingovpn@gmail.com",
      "passLabel": "Mot de passe",
      "passPlaceholder": "'***************',",
      "mainBtn": "Continuez",
      "resetLink": "Mot de passe oublié?",
      "otcBtn": "Envoyez-moi un code de connexion"
    },
    "register": {
      "title": "S'inscrire",
      "loginPre": "Vous avez déjà un compte?",
      "loginLink": "Connexion",
      "emailLabel": "Adresse e-mail",
      "emailPlaceholder": "dingovpn@gmail.com",
      "passLabel": "Mot de passe",
      "passPlaceholder": "'***************',",
      "mainBtn": "Continuez"
    },
    "resetPass": {
      "title": "Réinitialiser le mot de passe",
      "createPre": "Nouvel utilisateur?",
      "createLink": "Créer un compte Yéti",
      "emailLabel": "Adresse e-mail",
      "emailPlaceholder": "dingovpn@gmail.com",
      "mainBtn": "Envoyer le code",
      "resetLink": "Vous avez déjà un code de réinitialisation?",
      "otcBtn": "Envoyez-moi un code de connexion"
    },
    "updatePass": {
      "title": "Mettre à jour le mot de passe",
      "createPre": "Nouvel utilisateur?",
      "createLink": "Créer un compte Yéti",
      "codeLabel": "Réinitialiser le code",
      "codePlaceholder": "Réinitialiser le code",
      "passLabel": "Nouveau mot de passe",
      "passPlaceholder": "'***************',",
      "mainBtn": "Continuez",
      "resetLink": "Vous n'obtenez pas le code de réinitialisation?",
      "otcBtn": "Envoyez-moi un code de connexion"
    },
    "loginSendCode": {
      "title": "Connectez-vous avec le code",
      "createPre": "Nouvel utilisateur?",
      "createLink": "Créer un compte Yéti",
      "emailLabel": "Adresse e-mail",
      "emailPlaceholder": "dingovpn@gmail.com",
      "mainBtn": "Continuez",
      "otcLink": "Vous avez déjà un code de connexion?",
      "loginBtn": "Connectez-vous par mot de passe"
    },
    "loginCheckCode": {
      "title": "Connectez-vous avec le code",
      "createPre": "Nouvel utilisateur?",
      "createLink": "Créer un compte Yéti",
      "emailLabel": "Adresse e-mail",
      "emailPlaceholder": "Adingovpn@gmail.com",
      "codeLabel": "Code de connexion",
      "codePlaceholder": "123456",
      "mainBtn": "Continuez",
      "otcLink": "Vous n'obtenez pas de code de connexion?",
      "loginBtn": "Connectez-vous par mot de passe"
    },
    "remove": {
      "title": "Supprimer le compte",
      "message": "Êtes-vous sûr de vouloir vraiment supprimer définitivement votre compte Dingo ?\nCette action sera irréversible !\nVous devrez peut-être annuler manuellement votre abonnement après la suppression.",
      "cancelBtn": "Annuler",
      "removeBtn": "Envoyez-moi un e-mail pour supprimer le lien"
    }
  },
  "location": {
    "region": {
      "Dev": "Dev",
      "Auto": "Auto",
      "Favorites": "Favoris",
      "Latest": "Latest",
      "Free": "Gratuit",
      "World": "Monde",
      "Americas": "Amériques",
      "Europe": "L'Europe",
      "Asia": "Asie",
      "AsiaPacific": "Asie-Pacifique",
      "Australia": "Australie"
    },
    "country": {
      "CA": "Canada",
      "MX": "Mexique",
      "SE": "Suède",
      "US": "États-Unis",
      "NL": "Pays-Bas",
      "DE": "Allemagne",
      "GB": "Royaume-Uni",
      "FR": "France",
      "IN": "Inde",
      "KR": "Corée du Sud",
      "SG": "République de Singapour",
      "JP": "Japon",
      "AU": "Australie",
      "RU": "Russie",
      "FI": "Finlande",
      "HK": "RAS de Hong Kong",
      "CH": "Suisse",
      "ES": "Espagne",
      "PT": "Portugal",
      "MD": "Moldavie",
      "IE": "Irlande",
      "RO": "Roumanie",
      "HU": "Hongrie",
      "CZ": "République tchèque",
      "LV": "Lettonie",
      "UA": "Ukraine",
      "IL": "Israël",
      "TR": "Turquie",
      "PL": "Pologne",
      "BG": "Bulgarie",
      "KZ": "Kazakhstan",
      "RS": "Serbie",
      "SK": "Slovaquie",
      "IT": "Italie",
      "GR": "Grèce"
    },
    "city": {
      "dev": "Dev MSK",
      "auto_fastest": "Emplacement le plus rapide",
      "auto_nearest": "Pays le plus proche",
      "fr_paris_free": "Paris",
      "us_dallas_free": "Dallas",
      "de_frankfurt_free": "Francfort",
      "us_ny_free": "New York",
      "ru_moscow_free": "Moscou",
      "auto": "Auto",
      "ca_toronto": "Toronto",
      "se_stockholm": "Stockholm",
      "mx_mexico": "Mexico",
      "us_atlanta": "Atlanta",
      "us_chicago": "Chicago",
      "us_dallas": "Dallas",
      "us_la": "Los Angeles",
      "us_miami": "Miami",
      "us_ny": "New York",
      "us_sf": "San Francisco",
      "us_seattle": "Seattle",
      "us_sv": "Silicon Valley",
      "nl_amsterdam": "Amsterdam",
      "de_frankfurt": "Francfort",
      "gb_london": "Londres",
      "fr_paris": "Paris",
      "in_bengaluru": "Bengaluru",
      "kr_seoul": "Séoul",
      "sg_singapore": "Singapour",
      "jp_tokio": "Tokio",
      "au_sydney": "Sydney",
      "ru_moscow": "Moscou",
      "gb_coventry": "Coventry",
      "fi_helsinki": "Helsinki",
      "us_secaucus": "Secaucus",
      "hk_hongkong": "Hong Kong",
      "nl_meppel": "Meppel",
      "ch_zug": "Zug",
      "es_madrid": "Madrid",
      "pt_lisbon": "Lisbonne",
      "md_chisinau": "Chisinau",
      "ie_dublin": "Dublin",
      "ro_bucharest": "Bucarest",
      "hu_budapest": "Budapest",
      "cz_veseli": "Veseli",
      "lv_riga": "Riga",
      "ua_kyiv": "Kiev",
      "il_tel_aviv": "Tel Aviv",
      "tr_izmir": "Izmir",
      "pl_warsaw": "Varsovie",
      "bg_sofia": "Sofia",
      "kz_almaty": "Almaty",
      "rs_belgrade": "Belgrade",
      "sk_bratislava": "Bratislava",
      "it_rome": "Rome",
      "gr_thessaloniki": "Thessalonique",
      "us_secaucus_free": "Secaucus",
      "ca_beauharnois": "Beauharnois",
      "us_ashburn": "Ashburn",
    }
  }
}

import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Box, Button, Paper, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { Plan, Plans } from '#rc/Plans'
import { CustomPlan } from '#rc/CustomPlan'
import Download from '#rc/Download'
import * as requests from '#src/requests'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  accountState,
  tokenState,
  isSubscribedSelector,
} from '#state'

export default function AccountInfo(props) {
  const { plans } = props

  const account = useRecoilValue(accountState)
  const token = useRecoilValue(tokenState)
  const isSubscribed = useRecoilValue(isSubscribedSelector)

  const [subInfo, setSubInfo] = useState(null)
  const [selectedPlan, setSelectedPlan] = useState(null)

  useEffect(() => {
    const run = async () => {
      const paddle = account.payments?.paddle
      if (paddle?.subStatus?.status === 'ACTIVE') {
        const result = await requests.subscriptionInfo(token)
        if (result.success) {
          console.log('subInfo subscription', result)
          setSubInfo(result.subscription)
        } else {
          console.log('get subInfo failed', result)
        }
      }
    }
    if (account) {
      run()
    }
  }, [account])


  useEffect(() => {
    let selectedPlan2 = null
    if (account) { //isSubscribed) {
      const legacy = account?.payments?.legacy
      const paddle = account?.payments?.paddle
      const crypto = account?.payments?.crypto
      if (account.isTester) {
        // selectedPlan = plans.custom
      } else if (paddle || (legacy && legacy?.paymentInfo?.source === 'paddle')) {
        const sid = paddle?.paymentInfo?.subscription_plan_id || legacy?.paymentInfo?.subscription_plan_id
        console.log('selectedPlan sid', sid)
        selectedPlan2 =
          plans.common.find(
            plan =>
              plan.providers.paddle.id.toString() === sid
          ) || plans.custom
        console.log('selectedPlan paddle', selectedPlan2)
        setSelectedPlan(selectedPlan2)
      } else if (crypto || (legacy
        && (legacy?.paymentInfo?.source === 'yeticoin'
          || legacy?.paymentInfo?.source === 'crypto'))) {
        const sid = crypto?.paymentInfo?.planId || legacy?.paymentInfo?.planId
        selectedPlan2 = plans.common
          .find(plan => plan.id === sid)
          || plans.custom
        console.log('selectedPlan crypto', selectedPlan2)
        setSelectedPlan(selectedPlan2)
      } else if (legacy && legacy?.paymentInfo?.source === 'paypal') {
        selectedPlan2 =
          plans.common.find(
            plan => plan?.providers?.paypal?.id === legacy?.paymentInfo?.plan_id
          ) || plans.custom
        console.log('selectedPlan paypal', selectedPlan2)
        setSelectedPlan(selectedPlan2)
      }
    }
  }, [account])



  return (
    <>
      <Paper
        elevation={2}
        sx={{
          maxWidth: { xs: '90vw', md: '70vw', lg: '50vw' },
          margin: 'auto',
          px: 5,
          pt: 0,
          pb: 4,
          backgroundColor: 'transparent',
          backgroundImage: 'none',
          boxShadow: 'none',
        }}>
        <Typography variant="h5" sx={{
          my: 3,
          textAlign: 'center'
        }}>
          Account
        </Typography>

        {isSubscribed ? (
          <Active
            plans={plans}
            selectedPlan={selectedPlan}
            account={account}
            subInfo={subInfo}
          />
        ) : (
          <NotActive />
        )}
      </Paper>
      <Download />
    </>
  )
}

function NotActive() {
  const navigate = useNavigate()

  return (
    <>
      <Typography textAlign={'center'} component="div">
        DingoVPN Premium is
        <Box
          color={'error.main'}
          fontWeight='fontWeightMedium'
          display='inline'
        >
          {' '}not active
        </Box>
      </Typography>
      <Box textAlign={'center'} mt={2}>
        <Button
          onClick={() => {
            navigate('/order')
          }}>
          Find your subscription plan
        </Button>
      </Box>
    </>
  )
}

function Active({ account, plans, selectedPlan, subInfo }) {
  return (
    <>
      <Typography textAlign={'center'} component="div">
        DingoVPN Premium is
        <Box
          color={'success.main'}
          fontWeight='fontWeightMedium'
          display='inline'
        >
          {' '}active
        </Box>
      </Typography>

      <Typography textAlign={'center'}>
        Your current plan:
      </Typography>

      <CurrentSubscriptionPlan
        account={account}
        plans={plans}
        selectedPlan={selectedPlan}
        subInfo={subInfo}
      />
      <CurrentSubscriptionDetails subInfo={subInfo} />

      <ControlSubscription account={account} subInfo={subInfo} />
    </>
  )
}

function CurrentSubscriptionPlan({ account, plans, selectedPlan, subInfo }) {
  if (account.isTester) {
    return (
      <Typography textAlign={'center'}>
        TEST MODE
      </Typography>
    )
  } else {
    const source = account?.paymentInfo?.source
    const mobile = account?.payments?.ios || account?.payments?.android

    const isPaddle = account?.payments?.paddle || source === 'paddle'
    const isCrypto = account?.payments?.crypto || source === 'yeticoin' || source === 'crypto'

    console.log('CurrentSubscriptionPlan', isPaddle, isCrypto, selectedPlan, subInfo)
    if (isPaddle || isCrypto) {
      const legacy = account?.payments?.legacy
      const paddle = account?.payments?.paddle
      const crypto = account?.payments?.crypto
      const sid = +paddle?.paymentInfo?.subscription_plan_id
        || +legacy?.paymentInfo?.subscription_plan_id
        || +crypto?.paymentInfo?.planId
        || +subInfo?.plan_id

      if (plans && selectedPlan && (selectedPlan.id !== 'custom' || sid)) {
        let subPlan = selectedPlan
        console.log('CurrentSubscriptionPlan', sid, selectedPlan, plans)

        if (selectedPlan.id === 'custom') {
          const pdl = plans.custom.providers.paddle
          const index = pdl.findIndex((p) => p.id === sid)
          console.log('index', index)
          let customPlanMonths = index + 1
          let savePercent = 0
          for (let i = 0; i < customPlanMonths; i++) {
            savePercent += subPlan.monthSave[i]
          }
          const priceRes =
            ((subPlan.priceMonthUSD * customPlanMonths * (100 - savePercent)) / 100)

          const priceFull = `$${(subPlan.priceMonthUSD * customPlanMonths).toFixed(2)}`
          const monthlyPrice = `$${(priceRes / customPlanMonths).toFixed(2)}`
          const priceResult = `$${priceRes.toFixed(2)}`

          const ext = {
            price: monthlyPrice,
            priceFull,
            priceResult,
            priceUSD: 76.94,
            save: savePercent,
            periodDesc: `every ${customPlanMonths} months`,
          }
          subPlan = { ...subPlan, ...ext }
        }
        return (
          <>
            <Box mt={2}>
              <Plan
                plan={subPlan}
                selectedPlanID={subPlan.id}
                setSelectedPlanID={() => { }}
              />
            </Box>
          </>
        )
      } else {
        return (
          <Box mt={2}>
            <Skeleton variant="rounded" width={'100%'} height={150} sx={{ flex: '1 1 30%' }} />
          </Box>
        )
      }
    } else if (mobile || account?.paymentInfo?.source === 'inapp') {
      // mobile subscriptions
      let mobileSubActive = false
      if (mobile?.subStatus && mobile?.subStatus?.status) {
        console.log('mobile', mobile)
        const { status, nextPaymentTime } = mobile.subStatus
        if (status === 'ACTIVE') {
          mobileSubActive = true
        } else if (status === 'CANCELLED') {
          const nextPaymentTimeMs = new Date(nextPaymentTime).valueOf()
          if (nextPaymentTimeMs > Date.now()) {
            mobileSubActive = true
          }
        }
      }
      console.log('mobileSubActive', mobileSubActive)

      if (mobileSubActive && mobile.paymentInfo.os === 'ios') {
        console.log('ios plan', mobile.paymentInfo.purchaseData.product_id)
        return (
          <Typography textAlign={'center'}>
            iOS subscription
          </Typography>
        )
      } else if (mobileSubActive && (mobile.paymentInfo.os === 'android')) {
        console.log('android plan', mobile.paymentInfo.purchaseData.productId)
        return (
          <Typography textAlign={'center'}>
            Android subscription
          </Typography>
        )
      }
    }
  }

  return (
    <Typography textAlign={'center'}>
      Loading awailable subscription plans...
    </Typography>
  )
}

function ControlSubscription({ account, plans, selectedPlan, subInfo }) {
  const navigate = useNavigate()

  // if (account.isTester) {
  //   return null
  // } else
  if (account.subStatus.status === 'ACTIVE') {
    if (account.paymentInfo.source === 'paddle') {
      return (
        <Box textAlign={'center'} mt={2}>
          <Button
            onClick={() => {
              navigate('/cancel')
            }}>
            Cancel Subscription
          </Button>
          <Button
            onClick={() => {
              const Paddle = window.Paddle

              Paddle.Checkout.open({
                override: subInfo.update_url
              });
            }}>
            Update Payment Method
          </Button>
        </Box>
      )
    } else if (account.paymentInfo.source === 'inapp') {
      return null
    }
  }
}

function CurrentSubscriptionDetails(props) {
  const { subInfo } = props

  const account = useRecoilValue(accountState)

  const source = account?.paymentInfo?.source

  if (source === 'paddle' || source === 'crypto' || source === 'yeticoin') {
    if (subInfo && account.subStatus.status === 'ACTIVE') {
      return (
        <Box mt={2}>
          <Typography textAlign={'center'}>
            Next payment: {subInfo.next_payment.currency} {subInfo.next_payment.amount} {subInfo.next_payment.date}
          </Typography>
        </Box>
      )
    } else if (account.subStatus.status === 'CANCELLED') {
      const nextPaymentTime = account.subStatus.nextPaymentTime
      const endDateStr = new Date(nextPaymentTime).toLocaleString()
      return (
        <Box mt={2}>
          <Typography textAlign={'center'}>
            Your subscription is cancelled, but will be active untill
          </Typography>
          <Typography textAlign={'center'}>
            {endDateStr}
          </Typography>
        </Box>
      )
    }
  }

  return null
}


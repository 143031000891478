
const apiBase = 'https://api.dingovpn.com'
// const apiBase = 'http://localhost:3000'

export async function login(login, pass) {
  try {
    const response = await POST(`${apiBase}/account/login`, {
      os: getBrowserOS(),//application.os(),
      platform: getBrowserOS(),//application.platform(),
      arch: getBrowserArch(),//application.arch(),
      clientId: window.clientId,
      source: 'web',
      type: 'email',
      login,
      pass,
    })
    // console.log('login response', response)
    const data = await response.json()
    return data
  } catch (error) {
    console.log('login error inner', error)
    return { success: false }
  }
}

export async function register(login, pass, clickId, business = false, promoId) {
  try {
    const response = await POST(`${apiBase}/account/register`, {
      os: getBrowserOS(),//application.os(),
      platform: getBrowserOS(),//application.platform(),
      arch: getBrowserArch(),//application.arch(),
      clientId: window.clientId,
      source: 'web',
      clickId,
      type: 'email',
      login,
      pass,
      business,
      promoId,
    })
    // console.log('register response', response)
    const data = await response.json()
    return data
  } catch (error) {
    console.log('register error inner', error)
    return { success: false }
  }
}

export async function resetcode(email) {
  try {
    const response = await POST(`${apiBase}/account/code`, {
      os: getBrowserOS(),//application.os(),
      platform: getBrowserOS(),//application.platform(),
      arch: getBrowserArch(),//application.arch(),
      clientId: window.clientId,
      source: 'web',
      email,
    })
    // console.log('resetcode response', response)
    const data = await response.json()
    return data
  } catch (error) {
    console.log('resetcode error inner', error)
    return { success: false }
  }
}

export async function updatePass(code, password) {
  try {
    const response = await POST(`${apiBase}/account/reset`, {
      os: getBrowserOS(),//application.os(),
      platform: getBrowserOS(),//application.platform(),
      arch: getBrowserArch(),//application.arch(),
      clientId: window.clientId,
      source: 'web',
      code,
      password,
    })
    // console.log('updatePass response', response)
    const data = await response.json()
    return data
  } catch (error) {
    console.log('updatePass error inner', error)
    return { success: false }
  }
}

export async function otccode(email) {
  try {
    const response = await POST(`${apiBase}/account/otcsend`, {
      os: getBrowserOS(),//application.os(),
      platform: getBrowserOS(),//application.platform(),
      arch: getBrowserArch(),//application.arch(),
      clientId: window.clientId,
      source: 'web',
      email,
    })
    // console.log('otccode response', response)
    const data = await response.json()
    return data
  } catch (error) {
    console.log('otccode error inner', error)
    return { success: false }
  }
}

export async function otclogin(email, code) {
  try {
    const response = await POST(`${apiBase}/account/otccheck`, {
      os: getBrowserOS(),//application.os(),
      platform: getBrowserOS(),//application.platform(),
      arch: getBrowserArch(),//application.arch(),
      clientId: window.clientId,
      source: 'web',
      email,
      code,
    })
    // console.log('otclogin response', response)
    const data = await response.json()
    return data
  } catch (error) {
    console.log('otclogin error inner', error)
    return { success: false }
  }
}

// *******************************************************
// Other
// *******************************************************

export async function removeAccount(token) {
  try {
    const response = await POST(`${apiBase}/account/remove`, {
      os: getBrowserOS(),//application.os(),
      platform: getBrowserOS(),//application.platform(),
      arch: getBrowserArch(),//application.arch(),
      clientId: window.clientId,
      source: 'web',
      token
    })
    // console.log('removeAccount response', response)
    const data = await response.json()
    return data
  } catch (error) {
    console.log('removeAccount error inner', error)
    return { success: false }
  }
}

export async function promoCode(token, code) {
  try {
    const response = await POST(`${apiBase}/account/promo`, {
      os: getBrowserOS(),//application.os(),
      platform: getBrowserOS(),//application.platform(),
      arch: getBrowserArch(),//application.arch(),
      clientId: window.clientId,
      token,
      code,
    })
    // console.log('promoCode response', response)
    const data = await response.json()
    return data
  } catch (error) {
    console.log('promoCode error inner', error)
    return { success: false }
  }
}

export async function partnerPromoCode(token, promo) {
  try {
    const response = await POST(`${apiBase}/partner/check`, {
      os: getBrowserOS(),//application.os(),
      platform: getBrowserOS(),//application.platform(),
      arch: getBrowserArch(),//application.arch(),
      clientId: window.clientId,
      token,
      promo,
    })
    // console.log('partnerpromo response', response)
    const data = await response.json()
    return data
  } catch (error) {
    console.log('partnerPromoCode error inner', error)
    return { success: false }
  }
}

export async function createTransaction(token, promoCode, topUpAmount) {
  try {
    const response = await fetch(`${apiBase}/pay/create`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        token,
        promoCode,
        topUpAmount,
      })
    })
    const data = await response.json()
    console.log('/pay/create response', data)
    
    return data
  } catch (error) {
    console.log('create transaction error', error)
    return { success: false }
  }
}

export async function subscriptionInfo(token) {
  try {
    const response = await POST(`${apiBase}/account/subinfo`, {
      token
    })
    // console.log('promoCode response', response)
    const data = await response.json()
    return data
  } catch (error) {
    console.log('promoCode error inner', error)
    return { success: false }
  }
}

export async function cancelSubscription (subId, token, reason) {
  try {
    const response = await POST(`${apiBase}/account/cancel`, {
      subId,
      token,
      reason
    })
    console.log('cancelSubscription response', response)
    const data = await response.json()
    return data
  } catch (error) {
    console.log('cancelSubscription error', error)
    return { success: false }
  }
}

export async function businessInvite (email, token) {
  try {
    const response = await POST(`${apiBase}/account/business/invite`, {
      email,
      token
    })
    console.log('invite response', response)
    const data = await response.json()
    return data
  } catch (error) {
    console.log('invite error', error)
    return { success: false }
  }
}

export async function businessRemoveUser (id, token) {
  try {
    const response = await POST(`${apiBase}/account/business/remove`, {
      id,
      token
    })
    console.log('remove response', response)
    const data = await response.json()
    return data
  } catch (error) {
    console.log('remove error', error)
    return { success: false }
  }
}

export async function getStaticConfigsList (token) {
  try {
    const response = await POST(`${apiBase}/account/configs/list`, {
      token
    })
    const data = await response.json()
    console.log('getStaticConfigsList response', data)
    return data
  } catch (error) {
    console.log('getStaticConfigsList error', error)
    return { success: false }
  }
}

export async function deleteStaticConfig (peerId, token) {
  try {
    const response = await POST(`${apiBase}/account/configs/delete`, {
      peerId,
      token
    })
    console.log('deleteStaticConfig response', response)
    const data = await response.json()
    return data
  } catch (error) {
    console.log('deleteStaticConfig error', error)
    return { success: false }
  }
}

export async function createStaticConfig (name, location, token) {
  try {
    const response = await POST(`${apiBase}/account/configs/create`, {
      name,
      location,
      token
    })
    console.log('getStaticConfig response', response)
    const data = await response.json()
    return data
  } catch (error) {
    console.log('getStaticConfig error', error)
    return { success: false }
  }
}

export async function sendYetiReceipt ({ token, subId, months, coin, tx, signature }) {
  try {
    const response = await POST(`${apiBase}/receipt`, {
      coin,
      token,
      subId,
      months,
      tx,
      signature
    })
    console.log('sendYetiReceipt response', response)
    const data = await response.json()
    return data
  } catch (error) {
    console.log('sendYetiReceipt error', error)
    return { success: false }
  }
}

// *******************************************************
// Utils
// *******************************************************

export async function POST(url, body, retry = 0) {
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })

    return response
  } catch (error) {
    console.log('POST error', error)
    console.log('POST retry', retry)
    if (retry < 3) {
      return POST(url, body, ++retry)
    } else {
      throw error
    }
  }
}

function getBrowserArch() {
  let arch = "unknown";

  if (typeof window.navigator !== 'undefined') {
    if (window.navigator.platform.indexOf('Win') !== -1) {
      if (window.navigator.userAgent.indexOf('WOW64') !== -1 || window.navigator.userAgent.indexOf('Win64') !== -1) {
        arch = "x64";
      } else {
        arch = "x86";
      }
    } else if (window.navigator.platform.indexOf('Mac') !== -1) {
      if (window.navigator.userAgent.indexOf('Macintosh; ARM Mac OS X') !== -1 || window.navigator.userAgent.indexOf('Macintosh; ARM64 Mac OS X') !== -1) {
        arch = "arm64";
      } else {
        arch = "x64";
      }
    } else if (window.navigator.platform.indexOf('Linux') !== -1) {
      if (window.navigator.userAgent.indexOf('Linux aarch64') !== -1) {
        arch = "arm64";
      } else if (window.navigator.userAgent.indexOf('Linux x86_64') !== -1) {
        arch = "x64";
      } else if (window.navigator.userAgent.indexOf('Linux i686') !== -1) {
        arch = "x86";
      } else if (window.navigator.userAgent.indexOf('Linux armv') !== -1) {
        arch = "arm";
      }
    }
  }

  return arch;
}

function getBrowserOS() {
  let os = "unknown";
  const userAgent = window.navigator.userAgent;
  const platform = window.navigator.platform;

  if (platform.indexOf('Win') !== -1) {
    os = "Windows";
  } else if (platform.indexOf('Mac') !== -1) {
    os = "macOS";
  } else if (platform.indexOf('Linux') !== -1) {
    os = "Linux";
  } else if (platform.indexOf('Android') !== -1 || userAgent.indexOf('Android') !== -1) {
    os = "Android";
  } else if (platform.indexOf('iPhone') !== -1 || platform.indexOf('iPad') !== -1 || userAgent.indexOf('iPhone') !== -1 || userAgent.indexOf('iPad') !== -1) {
    os = "iOS";
  }

  return os;
}

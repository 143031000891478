import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState
} from 'recoil';
import { Stack, CircularProgress, Button, Container, Typography } from '@mui/material';
import {
  modalShowState,
  accountState,
  tokenState,
} from '#state'
import { cancelSubscription } from '#src/requests'

export default function CancelSub(props) {
  const navigate = useNavigate()
  const token = useRecoilValue(tokenState)
  const [account, setAccount] = useRecoilState(accountState)
  const setModalShow = useSetRecoilState(modalShowState)
  const [reason, setReason] = useState('')
  const [isAwaiting, setIsAwaiting] = useState(false)

  if (!account && !account.subStatus) return null

  const endDate = account.subStatus.nextPaymentTime || '01.01.2021'
  const subId = account.paymentInfo.id
  const endDateStr = new Date(endDate).toLocaleString()

  return (
    <Container sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
      >
        Are you sure?
      </Typography>
      <Typography
        id="modal-modal-description"
        textAlign={'center'}
        sx={{ mt: 2 }}
      >
        Your current subscription will be canceled, but you&apos;ll be able to
        use DingoVPN Premium untill
      </Typography>
      <Typography textAlign={'center'} sx={{ mt: 1 }}>
        {endDateStr}
      </Typography>
      <Stack
        direction="row"
        spacing={2}
        sx={{ mt: 2 }}
      >
        <Button
          variant="outlined"
          color="error"
          onClick={async () => {
            setIsAwaiting(true)
            const result = await cancelSubscription(subId, token, reason)
            console.log('cancelSubscription result', result)
            setIsAwaiting(false)
            setModalShow(false)
            setAccount(null)
            navigate('/')
          }}
        >
          {isAwaiting ? <CircularProgress color='inherit' size={20} /> : 'YES'}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setModalShow(false)
          }}>
          NO
        </Button>
      </Stack>
    </Container>
  )
}

import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState
} from 'recoil';
import { Box, Container, Typography, CircularProgress } from '@mui/material';

import { getTokenInfo, isSubscribedCheck } from '#src/utils'
import {
  accountState,
  tokenState,
  isSubscribedSelector,
} from '#state'

export default function Processing(props) {
  const token = useRecoilValue(tokenState)
  const setAccount = useSetRecoilState(accountState)
  const isSubscribed = useRecoilValue(isSubscribedSelector)

  const navigate = useNavigate()
  const [checkInterval, setCheckInterval] = useState(null)

  useEffect(() => {
    let interval = null
    // console.log('setCheckInterval', props)
    const checkToken = async () => {
      console.log('checkInterval tick', token)
      if (!token) return
      const accountInfo = await getTokenInfo(token)
      console.log('accountInfo', accountInfo)
      if (accountInfo.success) {
        const isSubscribed2 = isSubscribedCheck(accountInfo)
        console.log('checkToken isSubscribed2', isSubscribed2)
        if (isSubscribed2) {
          clearInterval(interval)
          setAccount(accountInfo)
          navigate(`/activated`)
        }
      }
    }
    checkToken()
    interval = setInterval(checkToken, 3000)
    return () => {
      clearInterval(interval)
    }
  }, [token])

  return (
    <Container sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2">
        Processing
      </Typography>
      <Typography
        id="modal-modal-description"
        textAlign={'center'}
        sx={{ mt: 2 }}
      >
        We are waiting for confirmation from the payment gateway system.
      </Typography>
      <Box sx={{ mt: 2 }} >
        <CircularProgress size={64} />
      </Box>
    </Container>
  )
}

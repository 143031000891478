import React from 'react';
import { useRecoilState } from 'recoil';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  // useSearchParams
} from 'react-router-dom'

import Processing from '#page/Modals/Processing'
import SubscriptionActivated from '#page/Modals/Activated'
import CancelSub from '#page/Modals/CancelSub'

import { modalShowState } from '#state'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 200,
  maxWidth: '90vw',
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  p: 4,
};

export function Modals(props) {
  const navigate = useNavigate()
  // const [searchParams] = useSearchParams()
  const location = useLocation()

  const [modalShow, setModalShow] = useRecoilState(modalShowState)

  return (
    <Modal
      open={modalShow}
      onClose={() => {
        setModalShow(false)
        if (location.pathname === '/activated') {
          navigate('/')
        }
      }}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={modalStyle}>
        <Routes location={location}>
          <Route
            path='/processing'
            element={<Processing />}
          />
          <Route
            path='/activated'
            element={<SubscriptionActivated />}
          />
          <Route
            path='/cancel'
            element={
              <CancelSub />
            }
          />
        </Routes>
      </Box>
    </Modal>
  )
}
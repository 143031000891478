import React, { useEffect, useState, useRef } from 'react'
import FuzzySearch from 'fuzzy-search'
// import { useTranslation } from 'react-i18next'
// mui
import Box from '@mui/material/Box'
import Input from '@mui/material/Input'
import TextField from '@mui/material/TextField'
// icons
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'

const useSearch = props => {
  const {
    servers,
    favourites,
    setServersFiltered,
    search,
    setFiltered,
    latestServerIDs
  } = props
  // const { t } = useTranslation()

  useEffect(() => {
    // console.log('servers', servers)
    const searcher = new FuzzySearch(
      servers,
      ['localName', 'enName', 'country', 'enCountry', 'countryCode'], // 'region'
      {
        caseSensitive: false
      }
    )
    const result = searcher.search(search)
    setFiltered(result)
    // console.log('filtered', result)

    // create filtered serverRegion structure
    const serversFiltered = []
    const regionsData = {}
    const regionsOrder = []
    result.map(item => {
      if (!regionsData[item.region]) {
        regionsData[item.region] = [item]
        regionsOrder.push(item.region)
      } else {
        regionsData[item.region].push(item)
      }
    })
    regionsOrder.map(region => {
      if (regionsData[region].length) {
        serversFiltered.push({
          region: region,
          data: regionsData[region]
        })
      }
    })

    // FAVOURITES
    if (search === '') {
      console.log('latestServerIDs', latestServerIDs)
      if (latestServerIDs && latestServerIDs.length) {
        const latest = servers.filter(srv => latestServerIDs.includes(srv.id))
        latest.sort((a, b) => {
          const aIndex = latestServerIDs.indexOf(a.id)
          const bIndex = latestServerIDs.indexOf(b.id)
          return aIndex - bIndex
        })
        if (latest.length) {
          serversFiltered.unshift({
            region: 'Latest',
            data: latest
          })
        }
      }

      if (favourites.length) {
        serversFiltered.unshift({ region: 'Favorites', data: favourites })
      }
      
      // serversFiltered.unshift({
      //   region: 'Auto',
      //   data: [
      //     {
      //       id: 'auto_fastest',
      //       name: 'Fastest Location',
      //       ignored: true
      //     },
      //     {
      //       id: 'auto_nearest',
      //       name: 'Nearest Country',
      //       ignored: true
      //     }
      //   ]
      // })
    }

    setServersFiltered(serversFiltered)
    // console.log('regionsFiltered', serversFiltered)
  }, [servers, search, latestServerIDs])
}

export default function Search (props) {
  const { servers, favourites, setServersFiltered, latestServerIDs } = props
  // const { t } = useTranslation()
  const searchInput = useRef(null)
  const [search, setSearch] = useState('')
  const [filtered, setFiltered] = useState(servers)

  useSearch({
    servers,
    favourites,
    setServersFiltered,
    search,
    setFiltered,
    latestServerIDs
  })

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-end',
        p: 1,
        pt: 0,
        position: 'relative'
      }}
    >
      {search.length ? (
        <ClearIcon
          sx={{
            color: 'action.active',
            mr: 1,
            my: 0.5,
            position: 'absolute',
            right: '20px',
            zIndex: 2,
            cursor: 'pointer'
          }}
          onClick={() => setSearch('')}
        />
      ) : (
        <SearchIcon
          sx={{
            color: 'action.active',
            mr: 1,
            my: 0.5,
            position: 'absolute',
            right: '20px'
          }}
        />
      )}
      <TextField
        ref={searchInput}
        value={search}
        onChange={ev => {
          setSearch(ev.target.value)
        }}
        id='loc-search'
        label={'Search'}
        variant='standard'
        sx={{ width: '92%', mx: 1 }}
      />
    </Box>
  )
}

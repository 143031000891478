// src/Footer.js

import React from 'react';
import { useTranslation } from 'react-i18next'
import { Container, Box, Typography, Link, Paper } from '@mui/material';
// import Grid from '@mui/material/Unstable_Grid2';

const Footer = () => {
  const { t } = useTranslation()
  
  return (
    <Paper elevation={2}>
      <Container maxWidth="lg">
        <Box py={2} textAlign="center" sx={{
          display: 'flex',
          justifyContent: 'space-evenly',
          flexWrap: 'wrap'
        }} >
          <Typography variant="body2" color={'inherit'}>
            Copyright © {new Date().getFullYear()} YETI Ecosystem FZ-LLC
          </Typography>
          <Typography variant="body2">
            <Link
              href="mailto:contact@dingovpn.com"
              target="_blank"
              rel="noopener noreferrer"
              color="inherit"
            >
              contact@dingovpn.com
            </Link>
          </Typography>
          <Typography variant="body2">
            <Link
              href="https://dingovpn.com/terms.html"
              target="_blank" rel="noopener noreferrer"
              color="inherit"
            >
              {t('paywall.terms')}
            </Link>
          </Typography>
          <Typography variant="body2">
            <Link
              href="https://dingovpn.com/privacy.html"
              target="_blank"
              rel="noopener noreferrer"
              color="inherit"
            >
              {t('paywall.privacy')}
            </Link>
          </Typography>
        </Box>
      </Container>
    </Paper>
  );
};

export default Footer;

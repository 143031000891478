const apiBase = 'https://api.dingovpn.com'
// const apiBase = 'http://localhost:3000'

export async function getTokenInfo(token) {
  try {
    const response = await fetch(`${apiBase}/account/token`, {
      method: 'POST',
      // mode: 'no-cors', // no-cors, *cors, same-origin
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        token
      })
    })
    // console.log('response', response)
    const data = await response.json()
    return data
  } catch (error) {
    console.log('getTokenInfo error', error)
    return { success: false }
  }
}

export async function getSubPlans (token) {
  try {
    const response = await fetch(`${apiBase}/subscriptions`)
    // console.log('response', response)
    const data = await response.json()
    return data
  } catch (error) {
    console.log('getSubPlans error', error)
    return null
  }
}

export function isSubscribedCheck(account) {
  if (account && account.isTester) {
    return true
  }

  if (account && account.subStatus && account.subStatus.status) {
    const { status, nextPaymentTime } = account.subStatus
    if (status === 'ACTIVE') return true
    else if (status === 'CANCELLED') {
      const nextPaymentTimeMs = new Date(nextPaymentTime).valueOf()
      if (nextPaymentTimeMs > Date.now()) return true
    }
  }
  return false
}


import paleteDark from './paleteDark'
import paleteLight from './paleteLight'

// theme images
import mapDark from '#assets/mapDark.svg'
import mapLight from '#assets/mapLight.svg'
// import alertDark from './assets/alertDark.svg'
// import alertLight from './assets/alertLight.svg'
import logoDark from '#assets/logo_inline_white.svg';
import logoLight from '#assets/logo_inline.svg';

export const getDesignTokens = mode => ({
  images: {
    ...(mode === 'dark' ? {
      logo: logoDark,
      map: mapDark,
    } : {
      logo: logoLight,
      map: mapLight,
    })
  },
  palette: {
    mode,
    ...(mode === 'dark' && paleteDark),
    ...(mode === 'light' && paleteLight)
  }
})

const serverRegionsDefault = [
  {
    region: 'Americas',
    data: [
      {
        id: 'ca_beauharnois',
        countryCode: 'CA'
      },
      {
        id: 'ca_toronto',
        countryCode: 'CA'
      },
      {
        id: 'us_ashburn',
        countryCode: 'US'
      },
      {
        id: 'us_atlanta',
        countryCode: 'US'
      },
      {
        id: 'us_secaucus',
        countryCode: 'US'
      },
    ]
  },
  {
    region: 'Europe',
    data: [
      {
        "id": "bg_sofia",
        "countryCode": "BG"
      },
      {
        "id": "cz_veseli",
        "countryCode": "CZ"
      },
      {
        "id": "fi_helsinki",
        "countryCode": "FI"
      },
      {
        "id": "fr_paris",
        "countryCode": "FR"
      },
      {
        "id": "de_frankfurt",
        "countryCode": "DE"
      },
      {
        "id": "gr_thessaloniki",
        "countryCode": "GR"
      },
      {
        "id": "hu_budapest",
        "countryCode": "HU"
      },
      {
        "id": "ie_dublin",
        "countryCode": "IE"
      },
      {
        "id": "il_tel_aviv",
        "countryCode": "IL"
      },
      {
        "id": "it_rome",
        "countryCode": "IT"
      },
      {
        "id": "lv_riga",
        "countryCode": "LV"
      },
      {
        "id": "md_chisinau",
        "countryCode": "MD"
      },
      {
        "id": "nl_meppel",
        "countryCode": "NL"
      },
      {
        "id": "pl_warsaw",
        "countryCode": "PL"
      },
      {
        "id": "pt_lisbon",
        "countryCode": "PT"
      },
      {
        "id": "ro_bucharest",
        "countryCode": "RO"
      },
      {
        "id": "ru_moscow",
        "countryCode": "RU"
      },
      {
        "id": "rs_belgrade",
        "countryCode": "RS"
      },
      {
        "id": "sk_bratislava",
        "countryCode": "SK"
      },
      {
        "id": "es_madrid",
        "countryCode": "ES"
      },
      {
        "id": "se_stockholm",
        "countryCode": "SE"
      },
      {
        "id": "ch_zug",
        "countryCode": "CH"
      },
      {
        "id": "tr_izmir",
        "countryCode": "TR"
      },
      {
        "id": "ua_kyiv",
        "countryCode": "UA"
      },
      {
        "id": "gb_coventry",
        "countryCode": "GB"
      },
      {
        "id": "gb_london",
        "countryCode": "GB"
      }
    ]
  },
  {
    region: 'AsiaPacific',
    data:
      [
        {
          "id": "au_sydney",
          "countryCode": "AU"
        },
        {
          "id": "hk_hongkong",
          "countryCode": "HK"
        },
        {
          "id": "kz_almaty",
          "countryCode": "KZ"
        },
        {
          "id": "sg_singapore",
          "countryCode": "SG"
        }
      ]
  },
]

module.exports = serverRegionsDefault
import React, { useEffect, useState } from 'react'
import ReactSlider from 'react-slider'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import { Box, CardActionArea } from '@mui/material';
import SelectDot from './SelectDot'
import { PancakeRates } from './Yeti'
import './CustomPlan.css'
import {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil'
import {
  appearanceState,
  customPlanMonthsState,
  partnerSessionState,
} from '#state'


export function PartnerOffer(props) {
  return (
    <Stack
      direction="row"
      spacing={{ xs: 0, sm: 1 }}
      sx={{
        mt: 1,
        mb: 2,
        flexDirection: { xs: 'column', sm: 'row' },
        justifyContent: 'space-between',
        alignItems: 'stretch',
        flexWrap: 'wrap',
      }}
    >
      <Plan {...props} />
    </Stack>
  )
}

export function Plan(props) {
  const { plans } = props

  const partnerSession = useRecoilValue(partnerSessionState)

  let planText = 'Business Prepaid'
  if (partnerSession) {
    let currencySymbol = partnerSession.currency
    if (partnerSession.currency === 'USD') {
      currencySymbol = '$'
      planText = `VIP ${currencySymbol}${partnerSession.amount}`
    }
    else if (partnerSession.currency === 'EUR') {
      currencySymbol = '€'
      planText = `VIP ${currencySymbol}${partnerSession.amount}`
    }
    // else if (partnerSession.currency === 'AED') {
    //   currencySymbol = 'د.إ'
    //   planText = `VIP ${currencySymbol}${partnerSession.amount}`
    // }
    else {
      planText = `VIP ${partnerSession.amount} ${currencySymbol}`
    }
  }

  if (!plans) {
    const selected = false
    return (
      <Card
        elevation={selected ? 3 : 0}
        sx={{
          minHeight: 180,
          backgroundFilter: 'blur(2px)',
          backgroundColor: '#ff562f11',
          mt: { xs: 1, sm: 0 },
          flex: { xs: '1 1 80%', sm: '1 1 30%', },
          border: '1px solid #77777755',
          borderColor: selected ? '#ff562f' : '#ff562f77'
        }}
      >
        <Skeleton variant="rounded" width={'100%'} height={'100%'} />
      </Card>
    )
  }

  const selected = false
  return (
    <Card
      elevation={selected ? 3 : 0}
      sx={{
        backgroundFilter: 'blur(2px)',
        backgroundColor: '#ff562f11',
        mt: { xs: 1, sm: 0 },
        flex: { xs: '1 1 80%', sm: '1 1 30%', },
        border: '1px solid #77777755',
        borderColor: selected ? '#ff562f' : '#ff562f77'
      }}
    >
      <CardActionArea sx={{
        height: '100%',
      }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Box sx={{
            display: 'flex',
            width: 1,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
            <Stack direction={{ xs: 'column', sm: 'row' }}>
              <Typography variant="h5" mr={1}>
                {'Special Promo'}
              </Typography>
            </Stack>

            <Chip
              label={`Limited Offer`}
              variant={selected ? 'default' : 'outlined'}
              color={selected ? 'primary' : 'default'}
              sx={{
                backgroundColor: selected ? '#ff562f' : 'default',
                borderColor: '#ff562f',
                color: selected ? '#ffffff' : '#ff562f'
              }}
            />
          </Box>
          <Typography
            variant="h4"
            color="text.primary"
            sx={{
              mb: 1,
              fontWeight: 500
            }}
          >
            {planText}
          </Typography>

          <Typography variant="body1" color="text.secondary">
            You will get all Partner bonuses * and prepaid Dingo Business Plan 
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

import React, { useEffect, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import localforage from 'localforage'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import CircularProgress from '@mui/material/CircularProgress';
// 
import { Field } from '#page/Auth/components'
import * as requests from '#src/requests'
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  accountState,
  tokenState,
  promoCodeState,
  couponState,
} from '#state'


export default function PromoCode(props) {
  const { t } = useTranslation()
  const theme = useTheme()

  const [promoCode, setPromoCode] = useRecoilState(promoCodeState)
  const [coupon, setCoupon] = useRecoilState(couponState)
  const [token, setToken] = useRecoilState(tokenState)
  const [account, setAccount] = useRecoilState(accountState)

  const [codeError, setCodeError] = useState('')
  const [showErrors, setShowErrors] = useState(false)
  const [isAwaiting, setIsAwaiting] = useState(false)

  useEffect(() => {
    if (promoCode === '') {
      const errMessage = 'Promo code could not be empty' //t('account.errors.emptyEmail')
      setCodeError(errMessage)
    } else {
      setCodeError('')
    }

  }, [promoCode])

  const check = async event => {
    if (isAwaiting) return null
    // app root reload \ rerender fix
    event.preventDefault()
    if (codeError) {
      console.log('promoCode incorrect')
      setShowErrors(true)
    } else {
      setIsAwaiting(true)
      let result = await requests.promoCode(token, promoCode)
      setIsAwaiting(false)
      if (!result.success) {
        console.log('register error', result)
        if (result.error) {
          setShowErrors(true)
          const errMessage = t(`account.errors.${result.error}`)
          console.log('errMessage', errMessage)
          setCodeError(errMessage)
        } else {
          setCodeError('')
        }
      } else {
        setShowErrors(false)
        console.log('get coupon success', result)
        setCoupon(result.couponCode)
      }
    }
  }

  return (
    <Paper
      elevation={0}
      sx={{
        py: 2,
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}>

      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
        }}
      >
        <Box
          sx={{
            flex: '1 1 50%',
            mr: 2,
          }}
        >
          <Field
            disabled={!!coupon}
            autoComplete='off'
            color={coupon ? 'success' : 'primary'}
            value={promoCode}
            setValue={setPromoCode}
            label={'Promo code'}
            placeholder={`DINGO-${new Date().getFullYear()}`}
            errorMessage={codeError}
            showErrors={showErrors}
          />
        </Box>
        <Button
          disabled={!!coupon || !account || isAwaiting}
          variant='outlined'
          size='large'
          onClick={check}
          color={coupon ? 'success' : 'primary'}
          sx={{
            borderColor: coupon
              ? `${theme.palette.success.main}!important`
              : undefined
          }}
        >
          <Typography
            sx={{
              color: coupon
                ? `${theme.palette.success.main}!important`
                : undefined
            }}
          >
            {isAwaiting
              ? <CircularProgress size={24} />
              : coupon ? 'Activated' : 'Apply'
            }
          </Typography>
        </Button>
      </Box>

    </Paper>
  )
}

// src/App.js

import React, { useState, useEffect, useMemo } from 'react';
import { Container, CssBaseline, useMediaQuery } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import localforage from 'localforage'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { motion, AnimatePresence } from 'framer-motion'
import './App.css'
import * as requests from '#src/requests'
// state
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  appearanceState,
  visitorIdState,
  accountEmailState,
  initEmailState,
  resetCodeState,
  loginCodeState,
  showAuthAwaiterState,
  accountState,
  tokenState,
  clickIdState,
  initPlanIdState,
  modalShowState,
  plansState,
  selectedPlanIDState,
  showPaddleLoaderState,
  promoCodeState,
  couponState,
  partnerCodeState,
  isSubscribedSelector,
  isPartnerCodeValidState,
  partnerSessionState,

} from '#state'
// 
import Layout from '#rc/Layout';
import { getDesignTokens } from './theme'

import { Modals } from './Modals';
// legacy
import {
  Link,
  Routes,
  Route,
  Outlet,
  useLocation,
  Navigate,
  useNavigate,
  useSearchParams
} from 'react-router-dom'

import {
  getTokenInfo,
  getSubPlans,
  isSubscribedCheck
} from './utils'
// 

import Order from '#page/Order'
import Business from '#page/Business'
import AccountInfo from '#page/Account'
import Users from '#page/Users'

import Download from '#rc/Download'
import StaticConfig from '#rc/StaticConfig'
import AccountRouter from './AccountRouter'

const Paddle = window.Paddle
const modalLocations = ['/processing', '/activated']

function getBestMatchLang(locales) {
  // console.log('navigator.languages', navigator.languages)
  const userLangs = navigator.languages.map(lang => lang.slice(0, 2))
  const localesList = locales //['en', 'ru'] // Object.keys(locales)
  let bestMatchLang = 'en'
  for (const lang of userLangs) {
    if (localesList.includes(lang)) {
      bestMatchLang = lang
      break
    }
  }
  return bestMatchLang
}

const {
  mainDefault,
  serverRegionsDefault,
  localesDefault
} = require('./configDefault')

const bestMatchLang = getBestMatchLang(mainDefault.languages)
const localesList = mainDefault.languages //Object.keys(localesDefault)
const resources = {}
for (const lang of localesList) {
  resources[lang] = { translation: localesDefault[lang] }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    // returnObjects: true,
    lng: bestMatchLang,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  })

function App() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const location = useLocation()
  // 
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const systemAppearance = prefersDarkMode ? 'dark' : 'light'
  const [appearance, setAppearance] = useRecoilState(appearanceState)
  const [visitorId, setVisitorId] = useRecoilState(visitorIdState)
  const [clickId, setClickId] = useRecoilState(clickIdState)
  const [initPlanId, setInitPlanId] = useRecoilState(initPlanIdState)
  const [email, setEmail] = useRecoilState(accountEmailState)
  const [showAuthAwaiter, setShowAuthAwaiter] = useRecoilState(showAuthAwaiterState)
  const [token, setToken] = useRecoilState(tokenState)
  const [account, setAccount] = useRecoilState(accountState)
  const [initEmail, setInitEmail] = useRecoilState(initEmailState)
  const [resetCode, setResetCode] = useRecoilState(resetCodeState)
  const [loginCode, setLoginCode] = useRecoilState(loginCodeState)
  const [promoCode, setPromoCode] = useRecoilState(promoCodeState)
  const [coupon, setCoupon] = useRecoilState(couponState)
  // 
  const [modalShow, setModalShow] = useRecoilState(modalShowState)
  const [plans, setPlans] = useRecoilState(plansState)
  const [selectedPlanID, setSelectedPlanID] = useRecoilState(selectedPlanIDState)
  const [showPaddleLoader, setShowPaddleLoader] = useRecoilState(showPaddleLoaderState)
  
  const isSubscribed = useRecoilValue(isSubscribedSelector)
  
  const [partnerCode, setPartnerCode] = useRecoilState(partnerCodeState)
  const [partnerValid, setPartnerValid] = useRecoilState(isPartnerCodeValidState)
  const [partnerSession, setPartnerSession] = useRecoilState(partnerSessionState)

  // const isSubscribed = isSubscribedCheck(account)
  const showPurchaseInfo = !isSubscribed && location.pathname === '/'

  const theme = useMemo(() => {
    const design = getDesignTokens(appearance)
    const resultTheme = createTheme(design)
    console.log('mui theme', resultTheme)
    return resultTheme
  }, [appearance])

  useEffect(() => {
    setAppearance(systemAppearance)
  }, [systemAppearance, setAppearance])

  useEffect(() => {
    const run = async () => {
      const fp = await FingerprintJS.load({ monitoring: false })
      const result = await fp.get()
      setVisitorId(result.visitorId)
      window.clientId = result.visitorId
    }
    run()
  }, [])

  useEffect(() => {
    const loc = modalLocations.includes(location.pathname)
    const cancel = isSubscribed && location.pathname === '/cancel'
    if (loc || cancel) {
      setModalShow(true)
    } else {
      setModalShow(false)
    }
  }, [isSubscribed, location])

  useEffect(() => {
    // Paddle.Environment.set('sandbox')
    console.log('Paddle.Status.libraryVersion', Paddle.Status.libraryVersion)
    Paddle.Setup({
      vendor: 139630, // sandbox - 4434,
      eventCallback: function (data) {
        // The data.event will specify the event type
        console.log(data.event, data.eventData)

        if (data.eventData.checkout.test_variant === "subscriptionManagement") {
          console.log('subscriptionManagement')
        } else if (data.eventData.checkout.test_variant === "newCheckout") {
          console.log('newCheckout')
          if (data.event === 'Checkout.Complete') {
            navigate('/processing')

            if (window.gtag) {
              const prices = data.eventData?.checkout?.prices?.vendor
              const product = data.eventData?.product
              const tid = data.eventData?.checkout?.id
              if (prices) {
                const total = parseFloat(prices?.total) - parseFloat(prices?.total_tax)
                const fee = total * 0.05 + 0.5
                const income = total - fee
                const currency = prices?.currency
                console.log('total income', currency, income)

                window.gtag('event', 'conversion', {
                  'send_to': 'AW-10986044252/hB9GCPfz0ZYYENz2xvYo',
                  'value': income,
                  'currency': currency,
                  'transaction_id': tid,
                });
                window.gtag('event', 'purchase', {
                  'value': income,
                  'currency': currency,
                  'transaction_id': tid,
                  items: [{
                    item_name: product?.name,
                    quantity: product?.quantity,
                    price: income,
                  }],
                });
              }
            }
          }
        }

        if (data.event === 'Checkout.Loaded') {
          setShowPaddleLoader(false)
        } else if (data.event === 'Checkout.Complete') {
          setShowPaddleLoader(false)
        } else if (data.event === 'Checkout.Close') {
          setShowPaddleLoader(false)
        }
      }
    })
  }, [])


  useEffect(() => {
    if (!token) {
      let t = searchParams.get('token')

      const run = async () => {
        if (t) {
          console.log('token', t)
          try {
            setToken(t)
            await localforage.setItem('token', t)
          } catch (err) {
            console.log('save token error', err)
          }
        } else {
          try {
            t = await localforage.getItem('token')
            if (t) {
              console.log('localforage token', t)
              setToken(t)
            } else {
              if (location.pathname === '/') {
                console.log('localforage no token, redirect to /order')
                navigate('/order')
              }
            }
          } catch (err) {
            console.log('get token error', err)
            navigate('/account/login')
          }
        }
      }
      run()
    }
  }, [location])

  // useEffect(() => {
  //   if (!token && location.pathname === '/') {
  //     navigate('/account/login')
  //   }
  // }, [token, location])

  useEffect(() => {
    const run = async () => {
      const initEmail = searchParams.get('email')
      if (initEmail) {
        console.log('initEmail', initEmail)
        setEmail(initEmail)
      }

      const initResetCode = searchParams.get('resetCode')
      if (initResetCode) {
        console.log('resetCode', initResetCode)
        setResetCode(initResetCode)
      }

      const initLoginCode = searchParams.get('loginCode')
      if (initLoginCode) {
        console.log('loginCode', initLoginCode)
        setLoginCode(initLoginCode)
      }

      const initPromoCode = searchParams.get('promo')
      if (initPromoCode) {
        console.log('promoCode', initPromoCode)
        setPromoCode(initPromoCode)
      }

      let initPartnerPromoCode = searchParams.get('partner')
      if (initPartnerPromoCode) {
        console.log('partnerPromoCode', initPartnerPromoCode)
        setPartnerCode(initPartnerPromoCode)
        try {
          localforage.setItem('partnerPromoCode', initPartnerPromoCode)
        } catch (err) {
          console.log('save partnerPromoCode error', err)
        }
      } else {
        try {
          initPartnerPromoCode = await localforage.getItem('partnerPromoCode')
          if (initPartnerPromoCode) {
            console.log('localforage partnerPromoCode', initPartnerPromoCode)
            setPartnerCode(initPartnerPromoCode)
          }
        } catch (err) {
          console.log('localforage get partnerPromoCode error', err)
        }
      }

      let initClickId = searchParams.get('c')
      if (initClickId) {
        console.log('clickId', initClickId)
        try {
          setClickId(initClickId)
          localforage.setItem('clickId', initClickId)
        } catch (err) {
          console.log('save clickId error', err)
        }
      } else {
        try {
          initClickId = await localforage.getItem('clickId')
          if (initClickId) {
            console.log('localforage initClickId', initClickId)
            setClickId(initClickId)
          }
        } catch (err) {
          console.log('localforage get initClickId error', err)
        }
      }

      const planId = searchParams.get('plan')
      if (planId) {
        console.log('initPlanId', planId)
        setInitPlanId(planId)
        setSelectedPlanID(planId)
      } else {
        setInitPlanId('')
      }
    }
    run()
  }, [])

  useEffect(() => {
    const run = async () => {
      let result = await requests.partnerPromoCode(token, partnerCode)
      if (!result.success) {
        console.log('partnerPromoCode failed', result)
        if (result.error) {
          console.log('partnerPromoCode error', result.error)
        }
      } else {
        console.log('partnerPromoCode success', result)
        setPartnerValid(result.success)
        setPartnerSession(result.data)
      }
    } 
    if (token && partnerCode) {
      // console.log('App partnerCode', partnerCode)
      run()
    }
  }, [token, partnerCode, setPartnerSession, setPartnerValid])

  useEffect(() => {
    if (account !== null && location.pathname === '/') {
      if (account) navigate('/dashboard')
      else navigate('/order')
    }
  }, [location, account])

  useEffect(() => {
    const run = async () => {
      setShowAuthAwaiter(true)
      const accountInfo = await getTokenInfo(token)
      console.log('accountInfo', accountInfo)
      if (accountInfo.success) {
        // const { id, email, paymentInfo } = accountInfo
        setEmail(accountInfo.email)
        setAccount(accountInfo)
        setShowAuthAwaiter(false)
      }
    }
    if (token && !account) {
      run()
    }

  }, [token, account]) // location

  useEffect(() => {
    const run = async () => {
      const plansRes = await getSubPlans()
      console.log('plansRes', plansRes)
      console.log('getSubPlans initPlanId', initPlanId)

      if (plansRes) {
        // const pid = initPlanId || plansRes.plans.common[0].id
        // setSelectedPlanID(pid)
        // setPaypalClientID(plansRes.paypalClientID)
        setPlans(plansRes.plans)
      }
    }
    run()
  }, [])

  useEffect(() => {
    if (plans && initPlanId !== null) {
      const pid = initPlanId || plans.common[0].id
      setSelectedPlanID(pid)
    }
  }, [plans, initPlanId])

  const accountRoutes = AccountRouter({ email, setEmail, setToken })

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Layout>
        <Routes>
          <Route
            path='/apps'
            element={
              <>
                <StaticConfig token={token} isSubscribed={isSubscribed} />
                <Download />
              </>
            }
          />

          <Route
            path='/dashboard'
            element={<AccountInfo account={account} plans={plans} />}
          />

          <Route
            path='/users'
            element={<Users account={account} token={token} />}
          />

          <Route path='/plans' element={<Navigate to='/order' replace={true} />} />
          <Route
            path='/order'
            element={
              <Order />
            }
          />

          <Route path='/business/' element={<Outlet />}>
            <Route
              path=''
              element={<Business />}
            />
            <Route
              path='success'
              element={<Business state={'success'} />}
            />
            <Route
              path='error'
              element={<Business state={'error'} />}
            />
          </Route>


          {accountRoutes}

          <Route
            path='*'
            element={!isSubscribed ? (
              <Order />
            ) : (
              <AccountInfo account={account} plans={plans} />
            )
            }
          />
        </Routes>

        <Modals />

      </Layout>
    </ThemeProvider>
  );
}

export default App;

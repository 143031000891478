import React, { useEffect, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { motion, AnimatePresence } from 'framer-motion'
import localforage from 'localforage'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { AccountWrapper, Field, SecureField, Awaiter } from './components'
import * as requests from '#src/requests'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  accountEmailState,
  loginCodeState,
  tokenState,
} from '#state'

const emailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

export default function CheckOTC (props) {
  const { root, to } = props
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [token, setToken] = useRecoilState(tokenState)
  const [email, setEmail] = useRecoilState(accountEmailState)
  const [code, setCode] = useRecoilState(loginCodeState)

  const [loginError, setLoginError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [showErrors, setShowErrors] = useState(false)
  const [isAwaiting, setIsAwaiting] = useState(false)

  useEffect(() => {
    if (email === '') {
      const errMessage = t('account.errors.emptyEmail')
      setLoginError(errMessage)
    } else if (!email.match(emailformat)) {
      const errMessage = t('account.errors.incorrectEmail')
      setLoginError(errMessage)
    } else {
      setLoginError('')
    }
    // check code
    if (code === '') {
      const errMessage = t('account.errors.emptyCode')
      setPasswordError(errMessage)
    } else {
      setPasswordError('')
    }
  }, [code, email])

  const check = async event => {
    // app root reload \ rerender fix
    event.preventDefault()
    if (loginError || passwordError) {
      console.log('code or pass incorrect')
      setShowErrors(true)
    } else {
      setIsAwaiting(true)
      const result = await requests.otclogin(email, code)
      setIsAwaiting(false)
      if (!result.success) {
        console.log('CheckOTC error', result)
        if (result.error) {
          setShowErrors(true)
          const errMessage = t(`account.errors.${result.error}`)
          console.log('errMessage', errMessage)
          setPasswordError(errMessage)
          setCode('')
        } else {
          setPasswordError('')
          setCode('')
        }
      } else {
        setShowErrors(false)
        setCode('')
        console.log('CheckOTC success', result)
        setToken(result.token)
        try {
          await localforage.setItem('token', result.token)
        } catch (err) {
          console.log('save token error', err)
        }
        navigate('/')
        // trackEvent('Login')
        // navigate('/', { replace: true })
      }
    }
  }

  return (
    <>
      <AccountWrapper root={root} to={to}>
        <Grid
          component='form'
          container
          direction='column'
          alignItems='center'
          justifyContent='space-evenly'
          sx={{
            px: 5,
            py: 5,
            borderRadius: 2,
            // border: '1px solid',
            // borderColor: 'action.selected'
          }}
          onSubmit={check}
        >
          <Grid
            item
            container
            direction='column'
            alignItems='center'
            justifyContent='space-between'
            sx={{
              minHeight: '15vh'
            }}
          >
            <Typography textAlign='center' variant='h4'>{t('account.loginCheckCode.title')}</Typography>

            <Grid item container alignItems='center' justifyContent='center'>
              <Typography>{t('account.loginCheckCode.createPre')}</Typography>
              <Button
                sx={{ textTransform: 'none', cursor: 'pointer' }}
                onClick={() => {
                  navigate('/account/register', { replace: true })
                }}
              >
                <Typography>{t('account.loginCheckCode.createLink')}</Typography>
              </Button>
            </Grid>
          </Grid>

          <Grid
            item
            container
            direction='column'
            alignItems='stretch'
            justifyContent='space-evenly'
            sx={{
              minHeight: '26vh'
            }}
          >
            <Field
              value={email}
              setValue={setEmail}
              label={t('account.loginCheckCode.emailLabel')}
              placeholder={t('account.loginCheckCode.emailPlaceholder')}
              errorMessage={loginError}
              showErrors={showErrors}
            />
            <SecureField
              value={code}
              setValue={setCode}
              label={t('account.loginCheckCode.codeLabel')}
              placeholder={t('account.loginCheckCode.codePlaceholder')}
              type='password'
              errorMessage={passwordError}
              showErrors={showErrors}
              secureTextEntry
            />
          </Grid>
          <Grid
            item
            container
            direction='column'
            alignItems='center'
            justifyContent='space-between'
            sx={{
              minHeight: '25vh'
            }}
          >
            <Button
              fullWidth
              variant='contained'
              size='large'
              type="submit"
            >
              <Typography>{t('account.loginCheckCode.mainBtn')}</Typography>
            </Button>
            <Button
              sx={{ textTransform: 'none', cursor: 'pointer' }}
              onClick={() => {
                navigate('/account/otclogin', { replace: true })
              }}
            >
              <Typography>{t('account.loginCheckCode.otcLink')}</Typography>
            </Button>
            <Button
              fullWidth
              variant='outlined'
              size='large'
              onClick={() => {
                navigate('/account/login', { replace: true })
              }}
            >
              <Typography>{t('account.loginCheckCode.loginBtn')}</Typography>
            </Button>
          </Grid>
        </Grid>
      </AccountWrapper>
      <Awaiter isAwaiting={isAwaiting} />
    </>
  )
}

import React, { useEffect, useState } from 'react'
import ReactSlider from 'react-slider'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import { Box, CardActionArea } from '@mui/material';
import SelectDot from './SelectDot'
import { PancakeRates } from './Yeti'
import './BusinessOffer.css'
import {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil'
import {
  appearanceState,
  customPlanMonthsState,
  topUpAmountState,
} from '#state'


export function BusinessOffer(props) {
  const {
    showBest,
    plans,
    selectedPlanID,
    setSelectedPlanID,
    selectedPlan
  } = props

  // const info = usePlanInfo(props)

  return (
    <Stack
      direction="row"
      spacing={{ xs: 0, sm: 1 }}
      sx={{
        mt: 1,
        mb: 2,
        flexDirection: { xs: 'column', sm: 'row' },
        justifyContent: 'space-between',
        alignItems: 'stretch',
        flexWrap: 'wrap',
      }}
    >
      <Plan {...props} />
    </Stack>
  )
}

function usePlanInfo(props) {
  const {
    selectedPlanID,
    setSelectedPlanID,
    plans,
    selectedPlan
  } = props

  const [customPlanMonths, setCustomPlanMonths] = useRecoilState(customPlanMonthsState)

  let months = customPlanMonths

  useEffect(() => {
    if (plans && plans.custom) {
      setCustomPlanMonths(plans.custom.monthsDefault)
    }
  }, [plans])

  switch (selectedPlanID) {
    case 'month':
      months = 1
      break
    case 'year':
      months = 12
      break
    case '2years':
      months = 24
      break
    default:
      months = customPlanMonths
      break
  }
  const { BNB, YETI, priceBNB, priceYETI } = PancakeRates({ selectedPlan, customPlanMonths })
  const offerPriceYETI = priceYETI * 0.6
  const monthlyPriceYETI = (offerPriceYETI / months).toFixed(2)

  if (!plans || !selectedPlanID) return {}

  const plan = plans.custom

  // console.log('plan', plan, plans)
  const selected = selectedPlanID === plan?.id
  // const savePercent = plan.monthSave * (customPlanMonths - 1)
  let savePercent = 0
  for (let i = 0; i < customPlanMonths; i++) {
    savePercent += plan.monthSave[i]
  }
  // const resultPrice = 1 * customPlanMonths * Math.E^(-customPlanMonths/24)
  const resultPrice =
    (plan.priceMonthUSD * customPlanMonths * (100 - savePercent)) / 100

  const fullPrice = plan.priceMonthUSD * customPlanMonths
  const monthlyPrice = (resultPrice / customPlanMonths).toFixed(2)

  let selectedFull = fullPrice
  let selectedResult = resultPrice
  if (!selected) {
    selectedFull = selectedPlan.priceFull && +selectedPlan.priceFull.slice(1) || +selectedPlan.price.slice(1)
    selectedResult = +selectedPlan.priceResult.slice(1)
  }

  const yetiProps = {
    plan,
    selected,
    savePercent,
    monthlyPrice,
    fullPrice,
    resultPrice,
    offerPriceYETI,
    monthlyPriceYETI,
    priceYETI,
    selectedResult
  }

  return yetiProps
}

export function Plan(props) {
  const {
    selectedPlanID,
    setSelectedPlanID,
    plans,
  } = props

  const appearance = useRecoilValue(appearanceState)
  const setTopUpAmount = useSetRecoilState(topUpAmountState)

  const [seats, setSeats] = useState(10)
  const [locations, setLocations] = useState(1)


  const serverPowerMultiplier = Math.ceil(seats / 100)

  const seatPrice = 10
  const locationPrice = 250

  const seatsPrice = seats * seatPrice
  const locationsPrice = locations * locationPrice * serverPowerMultiplier
  let monthlyPrice = seatsPrice + locationsPrice

  const info = usePlanInfo(props)
  const {
    plan,
    selected,
  } = info

  useEffect(() => { 
    setTopUpAmount(monthlyPrice)
  }, [seats, locations, setTopUpAmount])

  if (!selectedPlanID || !plans) {
    return (
      <Card
        elevation={selected ? 3 : 0}
        sx={{
          minHeight: 180,
          backgroundFilter: 'blur(2px)',
          backgroundColor: '#ff562f11',
          mt: { xs: 1, sm: 0 },
          flex: { xs: '1 1 80%', sm: '1 1 30%', },
          border: '1px solid #77777755',
          borderColor: selected ? '#ff562f' : '#ff562f77'
        }}
        onClick={() => {
          setSelectedPlanID(plan.id)
        }}
      >
        <Skeleton variant="rounded" width={'100%'} height={'100%'} />
      </Card>
    )
  }


  return (
    <Card
      elevation={selected ? 3 : 0}
      sx={{
        backgroundFilter: 'blur(2px)',
        backgroundColor: '#ff562f11',
        mt: { xs: 1, sm: 0 },
        flex: { xs: '1 1 80%', sm: '1 1 30%', },
        border: '1px solid #77777755',
        borderColor: selected ? '#ff562f' : '#ff562f77'
      }}
      onClick={() => {
        setSelectedPlanID(plan.id)
      }}
    >
      <CardActionArea sx={{
        height: '100%',
      }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Box sx={{
            display: 'flex',
            width: 1,
            mb: 2,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
            <Stack direction={{ xs: 'column', sm: 'row' }}>
              <Typography variant="h5">
                {`${seats} ${seats > 1 ? 'seats' : 'seat'}`}
              </Typography>
              <Typography variant="h5" sx={{
                mr: 1,
                ml: 1,
                display: { xs: 'none', sm: 'block' }
              }}>
                {' | '}
              </Typography>
              <Typography variant="h5">
                {`${locations} ${locations > 1 ? 'private server locations' : 'private server location'}`}
              </Typography>
            </Stack>

            <Chip
              label={`Contact us and get special offer`}
              variant={'outlined'}
              color={'default'}
              sx={{
                backgroundColor: 'default',
                borderColor: '#ff562f',
                color: '#ff562f'
              }}
            />
          </Box>


          <Typography
            variant="h6"
            color="text.primary"
            sx={{
              mb: 1,
              fontWeight: 500
            }}
          >
            Seats price €{seatsPrice}/mo
          </Typography>

          <ReactSlider
            marks
            className={`horizontal-slider CustomPlan-${appearance} BusinessSeats-${appearance}`}
            thumbClassName='seats-thumb'
            trackClassName='seats-track'
            markClassName='seats-mark'
            min={0}
            max={999}
            value={seats - 1}
            defaultValue={10}
            onBeforeChange={(value, index) => {
              if (!selected) {
                setSelectedPlanID(plan.id)
              }
              console.log(
                `onBeforeChange: ${JSON.stringify({
                  value,
                  index
                })}`
              )
            }}
            onChange={(value, index) => {
              // console.log(`ReactSlider onChange: value ${value} index ${index}`)
              // console.log(`ReactSlider setSeats ${value + 1}`)
              setSeats(value + 1)
            }}
            onAfterChange={(value, index) =>
              console.log(
                `onAfterChange: ${JSON.stringify({ value, index })}`
              )
            }
            renderTrack={(props, state) => <div {...props} />}
            renderThumb={(props, state) => (
              <div {...props}>{state.valueNow + 1}</div>
            )}
            renderMark={props => <div {...props} />}
          />

          <Typography
            variant="h6"
            color="text.primary"
            sx={{
              mb: 1,
              fontWeight: 500
            }}
          >
            Private servers price €{locationsPrice}/mo
          </Typography>

          <ReactSlider
            marks
            className={`horizontal-slider CustomPlan-${appearance} BusinessLocations-${appearance}`}
            thumbClassName='locations-thumb'
            trackClassName='locations-track'
            markClassName='locations-mark'
            min={-1}
            max={34}
            value={locations - 1}
            defaultValue={1}
            onBeforeChange={(value, index) => {
              if (!selected) {
                setSelectedPlanID(plan.id)
              }
              console.log(
                `onBeforeChange: ${JSON.stringify({
                  value,
                  index
                })}`
              )
            }}
            onChange={(value, index) => {
              // console.log(`ReactSlider onChange: value ${value} index ${index}`)
              // console.log(`ReactSlider setLocations ${value + 1}`)
              setLocations(value + 1)
            }}
            onAfterChange={(value, index) =>
              console.log(
                `onAfterChange: ${JSON.stringify({ value, index })}`
              )
            }
            renderTrack={(props, state) => <div {...props} />}
            renderThumb={(props, state) => (
              <div {...props}>{state.valueNow + 1}</div>
            )}
            renderMark={props => <div {...props} />}
          />


          <Typography
            variant="h5"
            color="text.primary"
            sx={{
              mt: 2,
              mb: 2,
              fontWeight: 500
            }}
          >
            Estimated total monthly costs €{monthlyPrice}/mo
          </Typography>

          {/* <Typography variant="body1" color="text.secondary">
            {plan.priceMonthUSD ? (
              <del style={{ marginRight: '4px' }}>
                ${fullPrice.toFixed(2)}
              </del>
            ) : null}
            <Typography
              variant="body1"
              component="span"
              color="info.main"
            >
              ${resultPrice.toFixed(2)}{' '}
            </Typography>
            {plan.periodDesc} *
          </Typography> */}
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

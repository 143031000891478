import { atom, selector } from "recoil";
import localforage from 'localforage'

const localForageEffect = (key, defaults) => ({ setSelf, onSet }) => {
  setSelf(localforage.getItem(key).then(savedValue =>
    savedValue != null
      ? JSON.parse(savedValue)
      : defaults
  ));

  // Subscribe to state changes and persist them to localforage
  onSet((newValue, _, isReset) => {
    isReset
      ? localforage.removeItem(key)
      : localforage.setItem(key, JSON.stringify(newValue));
  });
};

export const appearanceState = atom({
  key: 'appearanceState',
  default: 'dark'
});

// auth states
export const tokenState = atom({
  key: 'tokenState',
  default: '',
  // effects: [
  //   // localForageEffect('token', ''),
  // ],
});
export const accountState = atom({
  key: 'accountState',
  default: null
});
export const isSubscribedSelector = selector({
  key: 'isSubscribedSelector',
  get: ({ get }) => {
    const account = get(accountState)
    if (account && account.isTester) {
      return true
    }

    if (account && account.subStatus && account.subStatus.status) {
      const { status, nextPaymentTime } = account.subStatus
      if (status === 'ACTIVE') return true
      else if (status === 'CANCELLED') {
        const nextPaymentTimeMs = new Date(nextPaymentTime).valueOf()
        if (nextPaymentTimeMs > Date.now()) return true
      }
    }
    return false
  },
});

export const accountEmailState = atom({
  key: 'accountEmailState',
  default: ''
});


export const visitorIdState = atom({
  key: 'visitorIdState',
  default: ''
});
export const clickIdState = atom({
  key: 'clickIdState',
  default: ''
});
export const initPlanIdState = atom({
  key: 'initPlanIdState',
  default: null
});

// initital load params
export const initEmailState = atom({
  key: 'initEmailState',
  default: ''
});
export const resetCodeState = atom({
  key: 'resetCodeState',
  default: ''
});
export const loginCodeState = atom({
  key: 'loginCodeState',
  default: ''
});

// referral
export const promoCodeState = atom({
  key: 'promoCodeState',
  default: ''
});
export const couponState = atom({
  key: 'couponState',
  default: ''
});
export const partnerCodeState = atom({
  key: 'partnerCodeState',
  default: ''
});
export const isPartnerCodeValidState = atom({
  key: 'isPartnerCodeValidState',
  default: false
});
export const partnerSessionState = atom({
  key: 'partnerSessionState',
  default: null
});
export const topUpAmountState = atom({
  key: 'topUpAmountState',
  default: 0
});

// other
export const customPlanMonthsState = atom({
  key: 'customPlanMonthsState',
  default: 6
});
export const showAuthAwaiterState = atom({
  key: 'showAuthAwaiterState',
  default: false
});
export const modalShowState = atom({
  key: 'modalShowState',
  default: false
});
export const plansState = atom({
  key: 'plansState',
  default: null
});
export const selectedPlanIDState = atom({
  key: 'selectedPlanID',
  default: null
});
export const showPaddleLoaderState = atom({
  key: 'showPaddleLoaderState',
  default: false
});

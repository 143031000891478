import React from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Box, Stack, Typography, CardActionArea } from '@mui/material';
import Chip from '@mui/material/Chip';
import Skeleton from '@mui/material/Skeleton';
import CheckIcon from '@mui/icons-material/Check';
import {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil'
import {
  appearanceState,
} from '#state'

export function Motivation() {
  return (
    <>
      <Typography variant="subtitle1">
        Encrypt your internet connection to protect your data and privacy
      </Typography>
      <Stack
        sx={{ mt: 1 }}
        spacing={1}
      >
        <Box display={'flex'}>
          <CheckIcon color="primary" fontSize="small" sx={{ mr: 1 }} />
          <Typography variant="subtitle2">
            Strict no logs policy
          </Typography>
        </Box>
        <Box display={'flex'}>
          <CheckIcon color="primary" fontSize="small" sx={{ mr: 1 }} />
          <Typography variant="subtitle2">
            High-speed connections
          </Typography>
        </Box>
        <Box display={'flex'}>
          <CheckIcon color="primary" fontSize="small" sx={{ mr: 1 }} />
          <Typography variant="subtitle2">
            Powerful modern encryption
          </Typography>
        </Box>
      </Stack>
    </>
  )
}

export function Plans({ showBest, plans, selectedPlanID, setSelectedPlanID }) {
  if (plans && selectedPlanID) {
    return (
      <Stack
        direction="row"
        spacing={{ xs: undefined, sm: 1 }}
        sx={{
          mt: 2,
          mb: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between',
          alignItems: 'stretch',
          flexWrap: 'wrap',
        }}
      >
        {plans.common.map(plan => (
          <Plan
            showBest={showBest}
            key={`plan_${plan.id}`}
            plan={plan}
            selectedPlanID={selectedPlanID}
            setSelectedPlanID={setSelectedPlanID}
          />
        ))}
      </Stack>
    )
  }
  return (<>
    <Stack
      direction="row"
      spacing={{ xs: undefined, sm: 1 }}
      sx={{
        mt: 2,
        mb: 1,
        flexDirection: { xs: 'column', sm: 'row' },
        justifyContent: 'space-between',
        alignItems: 'stretch',
        flexWrap: 'wrap',
      }}
    >
      <Skeleton variant="rounded" width={'31%'} height={150} sx={{flex:'1 1 30%'}} />
      <Skeleton variant="rounded" width={'31%'} height={150} sx={{flex:'1 1 30%'}} />
      <Skeleton variant="rounded" width={'31%'} height={150} sx={{flex:'1 1 30%'}} />
    </Stack>
  </>)
}

export function Plan({ showBest, plan, selectedPlanID, setSelectedPlanID }) {
  const selected = selectedPlanID === plan.id
  const appearance = useRecoilValue(appearanceState)
  return (
    <Card
      elevation={selected ? 3 : 0}
      // variant={selected ? 'default' : 'outlined'}
      sx={{
        // mx: 1,
        mt: { xs: 1, sm: 0 },
        flex: { xs: '1 1 80%', sm: '1 1 30%', },
        border: '1px solid #77777755',
        borderColor: selected ? 'primary.main' : 'default'
      }}
      onClick={() => {
        setSelectedPlanID(plan.id)
      }}
    >
      <CardActionArea sx={{
        height: '100%',
      }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Box sx={{
            display: 'flex',
            width: 1,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
            {/* <SelectDot selected={selected} /> */}
            <Typography variant="h5" component="div">
              {plan.title}
            </Typography>
            <Chip
              label={`Save ${plan.save}%`}
              variant={selected ? 'default' : 'outlined'}
              color={selected ? 'primary' : 'default'}
            />
          </Box>
          <Typography
            variant="h5"
            color="text.primary"
            sx={{ mb: 1, fontWeight: 500 }}
          >
            {plan.price}/mo
          </Typography>
          <Typography variant="body1" color="text.secondary">

            {plan.priceFull ? <del style={{ marginRight: '4px' }}>
              {plan.priceFull}
            </del> : null}
            <Typography variant="body1" component="span" color="info.main">
              {plan.priceResult}{' '}
            </Typography>
            {plan.periodDesc} *
          </Typography>
          {showBest && plan.best ?
            <Box>
              <Typography
                variant="body2"
                color={appearance==='dark'? "#ffd400" : "#fb7a00"}
                sx={{ mt: 1 }}
              >
                You save the most by choosing this plan.
              </Typography>
            </Box> : null}
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

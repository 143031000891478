// src/Header.js

import React, { useState, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { motion, AnimatePresence } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import localforage from 'localforage'
import { AppBar, Paper, Toolbar, Typography, Box, IconButton, Button, Switch } from '@mui/material';
import Badge from '@mui/material/Badge';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import GroupIcon from '@mui/icons-material/Group';
import BusinessIcon from '@mui/icons-material/Business';
import AppsIcon from '@mui/icons-material/Apps';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { LightMode, DarkMode } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles'
// import { AuthContext } from '#ctx/auth';
import CircularProgress from '@mui/material/CircularProgress';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
  appearanceState,
  accountEmailState,
  tokenState,
  showAuthAwaiterState,
  accountState,
} from '#state'



const Header = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const account = useRecoilValue(accountState)
  const [appearance, setAppearance] = useRecoilState(appearanceState)
  const isDarkTheme = appearance === 'dark';
  // const { isAuthenticated, userEmail, login, logout } = useContext(AuthContext);

  const [mobileMenuAnchor, setMobileMenuAnchor] = useState(null);
  const isMobileMenuOpen = !!mobileMenuAnchor
  // console.log('pathname', location.pathname)
  return (<>
    <AppBar
      position="fixed"
      color="background"
      elevation={2}
      enableColorOnDark
    >
      <Toolbar sx={{ display: 'flex', flexWrap: 'wrap' }}>

        <Box sx={{
          display: 'flex',
          flexBasis: '30%',
          flexGrow: 1,
          alignItems: 'center',
          py: 1,
        }}>
          <Link to='/'>
            <img
              src={theme.images.logo}
              alt="Logo"
              style={{ maxHeight: 42, maxWidth: '30vw' }}
            />
          </Link>
        </Box>

        {account ? (
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
              <IconButton
                edge="end"
                color={location.pathname === '/' ? 'primary' : 'disabled'}
                aria-label="Home"
                onClick={() => {
                  navigate('/')
                }}
                sx={{ mr: 1 }}
              >
                <HomeIcon />
              </IconButton>

              <IconButton
                edge="end"
                color={location.pathname === '/order' ? 'primary' : 'disabled'}
                aria-label="Plans"
                onClick={() => {
                  navigate('/order')
                }}
                sx={{ mr: 1 }}
              >
                <ShoppingCartIcon />
              </IconButton>

              <IconButton
                edge="end"
                color={location.pathname === '/apps' ? 'primary' : 'disabled'}
                aria-label="Apps"
                onClick={() => {
                  navigate('/apps')
                }}
                sx={{ mr: 1 }}
              >
                <AppsIcon />
              </IconButton>

              <IconButton
                edge="end"
                color={location.pathname === '/business' ? 'primary' : 'disabled'}
                aria-label="Business"
                onClick={() => {
                  navigate('/business')
                }}
                sx={{ mr: 1 }}
              >
                <BusinessIcon />
              </IconButton>

              {account && account.isBusinessAccount ? (
                <IconButton
                  edge="end"
                  color={location.pathname === '/users' ? 'primary' : 'disabled'}
                  aria-label="Users"
                  onClick={() => {
                    navigate('/users')
                  }}
                  sx={{ mr: 1 }}
                >
                  <GroupIcon />
                </IconButton>) : null}

            </Box>
            <Box sx={{ display: { xs: 'flex', sm: 'none' } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={'primary-menu-mobile'}
                aria-haspopup="true"
                onClick={(event) => {
                  setMobileMenuAnchor(event.currentTarget);
                }}
                color="inherit"
                sx={{ mr: 2 }}
              >
                <MenuIcon />
              </IconButton>

            </Box>
          </Box>
        ) : null}

        <Box sx={{
          display: 'flex',
          flexGrow: 1,
          flexBasis: '30%',
          justifyContent: 'flex-end',
          alignItems: 'center'
        }}>
          <IconButton
            edge="end"
            color="inherit"
            aria-label="Toggle dark/light theme"
            onClick={() => {
              setAppearance(appearance === 'light' ? 'dark' : 'light');
            }}
            sx={{ mr: 1 }}
          >
            {isDarkTheme ? <DarkMode /> : <LightMode />}
          </IconButton>

          <AuthBlock />

        </Box>
      </Toolbar>
    </AppBar>
    <MobileMenu
      isMobileMenuOpen={isMobileMenuOpen}
      mobileMenuAnchor={mobileMenuAnchor}
      setMobileMenuAnchor={setMobileMenuAnchor}
    />
  </>);
};

function AuthBlock() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const accountEmail = useRecoilValue(accountEmailState)
  const [account, setAccount] = useRecoilState(accountState)
  const setToken = useSetRecoilState(tokenState)
  const showAuthAwaiter = useRecoilValue(showAuthAwaiterState)

  if (account) {
    const logout = async () => {
      setToken('');
      setAccount(null);
      try {
        await localforage.removeItem('token')
      } catch (err) {
        console.log('save token error', err)
      }
      navigate('/')
    };

    return (<>
      <Typography variant="body1" sx={{
        display: { xs: 'none', md: 'flex' },
        marginRight: 2
      }}>
        {accountEmail}
      </Typography>
      <Button
        disabled={showAuthAwaiter}
        variant="outlined"
        color="inherit"
        onClick={logout}
      >
        {
          showAuthAwaiter
            ? <CircularProgress size={24} />
            : t('settings.accountLogout')
        }
      </Button>
    </>)
  }

  const login = () => {
    navigate('/account/login')
  };

  return (
    <>
      <Typography variant="body1" sx={{
        display: { xs: 'none', md: 'flex' },
        marginRight: 2
      }}>
        {t('account.login.createPre')}
      </Typography>
      <Button
        disabled={showAuthAwaiter}
        variant="outlined"
        color="inherit"
        onClick={login}>
        {
          showAuthAwaiter
            ? <CircularProgress size={24} />
            : t('settings.accountLogin')
        }
      </Button>
    </>
  )

}


function MobileMenu(props) {
  const {
    isMobileMenuOpen,
    mobileMenuAnchor,
    setMobileMenuAnchor,
  } = props

  const account = useRecoilValue(accountState)
  const location = useLocation()
  const { t } = useTranslation()
  const navigate = useNavigate()

  if (!account) {
    return null
  }

  return (
    <Paper>
      <Menu
        keepMounted
        id={'primary-menu-mobile'}
        anchorEl={mobileMenuAnchor}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',

        }}
        open={isMobileMenuOpen}
        onClose={() => {
          setMobileMenuAnchor(null);
        }}
      >
        <MenuList>
          <Typography variant="body1" sx={{
            textAlign: 'center',
            pb: 2,
          }}>
            NAVIGATION
          </Typography>

          <Divider />

          <MenuItem
            sx={{ minWidth: '8rem' }}
            onClick={() => {
              navigate('/')
            }}>
            <ListItemIcon>
              <HomeIcon
                color={location.pathname === '/' ? 'primary' : 'inherit'}
                fontSize="small"
              />
            </ListItemIcon>
            <ListItemText
              color={location.pathname === '/' ? 'primary' : 'inherit'}
              primary="Home"
            />
          </MenuItem>

          {account && account.isBusinessAccount ? (
            <MenuItem
              sx={{ minWidth: '8rem' }}
              onClick={() => {
                navigate('/users')
              }}>
              <ListItemIcon>
                <GroupIcon
                  color={location.pathname === '/users' ? 'primary' : 'inherit'}
                  fontSize="small"
                />
              </ListItemIcon>
              <ListItemText
                color={location.pathname === '/users' ? 'primary' : 'inherit'}
                primary="Users"
              />
            </MenuItem>) : null}

          <MenuItem
            sx={{ minWidth: '8rem' }}
            onClick={() => {
              navigate('/order')
            }}>
            <ListItemIcon>
              <ShoppingCartIcon
                color={location.pathname === '/order' ? 'primary' : 'inherit'}
                fontSize="small"
              />
            </ListItemIcon>
            <ListItemText
              color={location.pathname === '/order' ? 'primary' : 'inherit'}
              primary="Plans"
            />

          </MenuItem>

          <MenuItem
            sx={{ minWidth: '8rem' }}
            onClick={() => {
              navigate('/apps')
            }}>
            <ListItemIcon>
              <AppsIcon
                color={location.pathname === '/apps' ? 'primary' : 'inherit'}
                fontSize="small" />
            </ListItemIcon>
            <ListItemText
              color={location.pathname === '/apps' ? 'primary' : 'inherit'}
              primary="Apps"
            />
          </MenuItem>
        </MenuList>
      </Menu>
    </Paper>
  )
}

export default Header;

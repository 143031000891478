import React, { useEffect, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import localforage from 'localforage'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import CircularProgress from '@mui/material/CircularProgress';
// 
import { Field } from '#page/Auth/components'
import * as requests from '#src/requests'
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  accountState,
  tokenState,
  partnerCodeState,
  isPartnerCodeValidState,
  partnerSessionState,
  couponState,
} from '#state'


export default function PartnerPromoCode(props) {
  const { t } = useTranslation()
  const theme = useTheme()

  const [promoCode, setPromoCode] = useRecoilState(partnerCodeState)
  const [valid, setValid] = useRecoilState(isPartnerCodeValidState)
  const [partnerSession, setPartnerSession] = useRecoilState(partnerSessionState)
  const [token, setToken] = useRecoilState(tokenState)
  const [account, setAccount] = useRecoilState(accountState)

  const [codeError, setCodeError] = useState('')
  const [showErrors, setShowErrors] = useState(false)
  const [isAwaiting, setIsAwaiting] = useState(false)

  useEffect(() => {
    setCodeError('')
  }, [promoCode])

  useEffect(() => {
    if (promoCode && promoCode.length === 21) { // account && 
      check()
    }
  }, [promoCode, account])

  const check = async event => {
    if (isAwaiting) return null
    // app root reload \ rerender fix
    if (event) event?.preventDefault()
    if (codeError) {
      console.log('partner info incorrect')
      setShowErrors(true)
    } else {
      setIsAwaiting(true)
      let result = await requests.partnerPromoCode(token, promoCode)
      setIsAwaiting(false)
      if (!result.success) {
        console.log('partner info error', result)
        if (result.error) {
          setShowErrors(true)
          const errMessage = t(`account.errors.${result.error}`)
          console.log('errMessage', errMessage)
          setCodeError(errMessage)
          setValid(false)
        } else {
          setCodeError('')
          setValid(false)
        }
      } else {
        setShowErrors(false)
        console.log('get partner info success', result)
        setValid(result.success)
        setPartnerSession(result.data)
      }
    }
  }

  return (
    <Paper
      elevation={0}
      sx={{
        py: 2,
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}>

      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
        }}
      >
        <Box
          sx={{
            flex: '1 1 50%',
            mr: 2,
          }}
        >
          <Field
            disabled={!!(promoCode && valid) || !account || !!isAwaiting}
            autoComplete='off'
            color={valid ? 'success' : 'primary'}
            value={promoCode}
            setValue={setPromoCode}
            label={'Promo code'}
            placeholder={`DINGO-${new Date().getFullYear()}`}
            errorMessage={codeError}
            showErrors={showErrors}
          />
        </Box>
        <Button
          disabled={!promoCode || !!(promoCode && valid) || !account || isAwaiting}
          variant='outlined'
          size='large'
          onClick={check}
          color={valid ? 'success' : 'primary'}
          sx={{
            borderColor: valid
              ? `${theme.palette.success.main}!important`
              : undefined
          }}
        >
          <Typography
            sx={{
              color: valid
                ? `${theme.palette.success.main}!important`
                : undefined
            }}
          >
            {isAwaiting
              ? <CircularProgress size={24} />
              : valid ? 'Activated' : 'Apply'
            }
          </Typography>
        </Button>
      </Box>

    </Paper>
  )
}

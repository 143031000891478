const locations = {
  region: {
    Dev: "Dev",
    Auto: 'Quick Connect',
    Favorites: 'Favorites',
    Latest: 'Latest',
    Free: 'Free',
    World: 'World',
    Americas: 'Americas',
    Europe: 'Europe',
    Asia: 'Asia',
    AsiaPacific: 'Asia-Pacific',
    Australia: 'Australia'
  },
  country: {
    CA: 'Canada',
    MX: 'Mexico',
    SE: 'Sweden',
    US: 'United States',
    NL: 'Netherlands',
    DE: 'Germany',
    GB: 'United Kingdom',
    FR: 'France',
    IN: 'India',
    KR: 'South Korea',
    SG: 'Republic of Singapore',
    JP: 'Japan',
    AU: 'Australia',
    RU: 'Russia',
    FI: 'Finland',
    HK: 'Hong Kong',
    CH: 'Switzerland',
    ES: 'Spain',
    PT: 'Portugal',
    MD: 'Moldova',
    IE: 'Ireland',
    RO: 'Romania',
    HU: 'Hungary',
    CZ: 'Czech Republic',
    LV: 'Latvia',
    UA: 'Ukraine',
    IL: 'Israel',
    TR: 'Turkey',
    PL: 'Poland',
    BG: 'Bulgaria',
    KZ: 'Kazakhstan',
    RS: 'Serbia',
    SK: 'Slovakia',
    IT: 'Italy',
    GR: 'Greece',
  },
  city: {
    dev: 'Dev MSK',
    auto_fastest: 'Fastest Location',
    auto_nearest: 'Nearest Country',
    fr_paris_free: 'Paris',
    us_dallas_free: 'Dallas, TX',
    de_frankfurt_free: 'Frankfurt',
    us_ny_free: 'New York, NY',
    ru_moscow_free: 'Moscow',
    auto: 'Auto',
    ca_toronto: 'Toronto',
    se_stockholm: 'Stockholm',
    mx_mexico: 'Mexico City',
    us_atlanta: 'Atlanta, GA',
    us_chicago: 'Chicago, IL',
    us_dallas: 'Dallas, TX',
    us_la: 'Los Angeles, CA',
    us_miami: 'Miami, FL',
    us_ny: 'New York, NY',
    us_sf: 'San Francisco, CA',
    us_seattle: 'Seattle, WA',
    us_sv: 'Silicon Valley, CA',
    nl_amsterdam: 'Amsterdam',
    de_frankfurt: 'Frankfurt',
    gb_london: 'London',
    fr_paris: 'Paris',
    in_bengaluru: 'Bengaluru',
    kr_seoul: 'Seoul',
    sg_singapore: 'Singapore',
    jp_tokio: 'Tokio',
    au_sydney: 'Sydney',
    ru_moscow: 'Moscow',
    gb_coventry: 'Coventry',
    fi_helsinki: 'Helsinki',
    us_secaucus: 'Secaucus, NJ',
    hk_hongkong: 'Hong Kong',
    nl_meppel: 'Meppel',
    ch_zug: 'Zug',
    es_madrid: 'Madrid',
    pt_lisbon: 'Lisbon',
    md_chisinau: 'Chisinau',
    ie_dublin: 'Dublin',
    ro_bucharest: 'Bucharest',
    hu_budapest: 'Budapest',
    cz_veseli: 'Veseli nad Luznici',
    lv_riga: 'Riga',
    ua_kyiv: 'Kyiv',
    il_tel_aviv: 'Tel Aviv-Yafo',
    tr_izmir: 'Izmir',
    pl_warsaw: 'Warsaw',
    bg_sofia: 'Sofia',
    kz_almaty: 'Almaty',
    rs_belgrade: 'Belgrade',
    sk_bratislava: 'Bratislava',
    it_rome: 'Rome',
    gr_thessaloniki: 'Thessaloniki',
    us_secaucus_free: 'Secaucus, NJ',
    ca_beauharnois: 'Beauharnois',
    us_ashburn: 'Ashburn, VA',
  }
}

module.exports = locations

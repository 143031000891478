import React, { useEffect, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import localforage from 'localforage'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import CircularProgress from '@mui/material/CircularProgress';
// 
import { Field } from '#page/Auth/components'
import * as requests from '#src/requests'
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  topUpAmountState,
} from '#state'


export default function BusinessTopUpAmount(props) {
  const { t } = useTranslation()
  const theme = useTheme()

  const [topUpAmount, setTopUpAmount] = useRecoilState(topUpAmountState)
  const [amount, setAmount] = useState(topUpAmount)
  const [valid, setValid] = useState(true)

  const [codeError, setCodeError] = useState('')
  const [showErrors, setShowErrors] = useState(false)
  const [isAwaiting, setIsAwaiting] = useState(false)

  useEffect(() => {
    setCodeError('')
    setShowErrors(false)
  }, [topUpAmount])

  useEffect(() => {
    try {
      const parsed = parseFloat(amount)
      if (parsed !== topUpAmount) {
        setAmount(topUpAmount)
      }

    } catch (error) {

    }
  }, [topUpAmount, amount])

  const validateAmount = value => {
    setAmount(value)
    console.log('validateAmount', value)
    try {
      const parsed = parseFloat(value)
      if (parsed < 10) {
        setCodeError('Amount must be at least €10')
        setTopUpAmount(10)
        setValid(false)
      } else if (parsed > 100000) {
        setCodeError('Amount must be less than €100,000')
        setTopUpAmount(100000)
        setValid(false)
      } else {
        setCodeError('')
        setValid(true)
        setTopUpAmount(parsed)
      }
    } catch (err) {
      setCodeError('Invalid amount')
      setValid(false)
    }
  }

  return (
    <Paper
      elevation={0}
      sx={{
        py: 2,
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}>

      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
        }}
      >
        <Box
          sx={{
            flex: '1 1 50%',
            mr: 2,
          }}
        >
          <Field
            // disabled={!account || !!isAwaiting}
            autoComplete='off'
            color={valid ? 'success' : 'error'}
            value={amount}
            setValue={validateAmount}
            label={`Top up amount (EUR) ${showErrors && codeError ? codeError : ''}`}
            placeholder={`€1000`}
            errorMessage={codeError}
            showErrors={showErrors}
          />
        </Box>
      </Box>

    </Paper>
  )
}

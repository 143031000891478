/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Stack, Paper, Button, Typography } from '@mui/material';
import MuiLink from '@mui/material/Link';
import { CustomPlan } from '#rc/CustomPlan'
import { BusinessOffer } from '#rc/BusinessOffer'
import { PartnerOffer } from '#rc/PartnerOffer'
import FastRegister from '#rc/FastRegister'
import PartnerPromoCode from '#rc/PartnerPromoCode'
import BusinessTopUpAmount from '#rc/BusinessTopUpAmount'
import PartnerLegalInfo from '#rc/PartnerLegalInfo'
import * as requests from '#src/requests'
// state
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  accountState,
  tokenState,
  plansState,
  customPlanMonthsState,
  selectedPlanIDState,
  showPaddleLoaderState,
  partnerCodeState,
  isPartnerCodeValidState,
  partnerSessionState,
  couponState,
  initPlanIdState,
  clickIdState,
  topUpAmountState,
} from '#state'

import CheckIcon from '@mui/icons-material/Check';

export default function Business(props) {
  // const location = useLocation()
  // const navigate = useNavigate()

  const account = useRecoilValue(accountState)
  const token = useRecoilValue(tokenState)
  const plans = useRecoilValue(plansState)

  const partnerCode = useRecoilValue(partnerCodeState)
  const isPartnerCodeValid = useRecoilValue(isPartnerCodeValidState)

  const [selectedPlanID, setSelectedPlanID] = useRecoilState(selectedPlanIDState)
  const [showButtonLoader, setShowButtonLoader] = useRecoilState(showPaddleLoaderState)
  const [initPlanId, setInitPlanId] = useRecoilState(initPlanIdState)

  const { state } = props

  const selectedPlan =
    plans?.common.find(plan => {
      const pid = selectedPlanID || initPlanId
      return plan.id === pid
    }) || plans?.custom

  if (state === 'success') {
    return <TransactionSuccess />
  } else if (state === 'error') {
    return <TransactionError />
  }

  return (
    <main>
      <BusinessMotivation />

      <PurchaseStep>
        1. Create or login your Dingo Business Account
      </PurchaseStep>

      <FastRegister business={true} promoId={null} />

      <PurchaseStep>
        2. Enter your promo / offer code (optional)
      </PurchaseStep>

      <PartnerPromoCode />

      <PurchaseStep>{partnerCode && isPartnerCodeValid ? '3. Select your subscription options' : '3. Estimate usage and select your payment amount'} </PurchaseStep>

      {partnerCode && isPartnerCodeValid
        ? <PartnerOffer plans={plans} />
        : <>
          <BusinessOffer
            plans={plans}
            selectedPlanID={selectedPlanID}
            setSelectedPlanID={setSelectedPlanID}
            selectedPlan={selectedPlan}
          />
          <BusinessTopUpAmount />
        </>
      }

      <PurchaseStep>
        4. Complete your purchase
      </PurchaseStep>

      {partnerCode && isPartnerCodeValid ? <Typography
        variant="body1"
        color="text.primary"
        sx={{
          mt: 2,
          display: 'inline-block',
        }}
      >
        After completing your payment, you will be redirected back to the provider.
      </Typography> : null}


      <PurchaseButtons
        plans={plans}
        selectedPlan={selectedPlan}
        selectedPlanID={selectedPlanID}
        account={account}
        token={token}
        showButtonLoader={showButtonLoader}
        setShowButtonLoader={setShowButtonLoader}
      />
    </main>
  )
}

function PurchaseStep({ children }) {
  return (
    <Box >
      <Typography
        variant="body1"
        color="text.primary"
        sx={{
          mt: 2,
          pr: 0.5,
          display: 'inline-block',
          borderBottom: '1px solid',
          borderColor: 'text.primary',
        }}
      >
        {children}
      </Typography>
    </Box>
  )
}

function PurchaseButtons(props) {
  const {
    selectedPlanID,
    selectedPlan,
    account,
    token,
    plans,
    showButtonLoader,
    setShowButtonLoader
  } = props

  const [promoCode, setPromoCode] = useRecoilState(partnerCodeState)
  const [valid, setValid] = useRecoilState(isPartnerCodeValidState)
  const [topUpAmount, setTopUpAmount] = useRecoilState(topUpAmountState)

  const [customPlanMonths, setCustomPlanMonths] = useRecoilState(customPlanMonthsState)
  const coupon = useRecoilValue(couponState)
  const clickId = useRecoilValue(clickIdState)
  const [buttonText, setButtonText] = useState('Pay with Card')
  const [productInfo, setProductInfo] = useState(null)

  useEffect(() => {
    if (!account) {
      setButtonText('You need to login or register first')
    } else {
      setButtonText('Checkout')
    }
  }, [account])

  if (account) {
    const { id, email, paymentInfo } = account

    return (
      <>
        <Box className=''>
          <Box className='dingo-payment'>
            <Button
              fullWidth
              variant="outlined"
              color="inherit"
              sx={{ mt: 2 }}
              onClick={async () => {
                setShowButtonLoader(true)

                try {
                  let result = await requests.createTransaction(token, promoCode, topUpAmount)
                  console.log('createTransaction', result)
                  if (result.success) {
                    // openInNewTab(result.url)
                    window.location.href = result.url;
                  }
                } catch (error) {
                  console.log('createTransaction error', error)
                }

                setShowButtonLoader(false)
                // if (window.gtag) {
                //   window.gtag('event', 'conversion', { 'send_to': 'AW-10986044252/DGaiCNnJp5oYENz2xvYo' });
                //   window.gtag('event', 'begin_checkout', {
                //     'event_callback': () => {
                //       console.log('begin_checkout')
                //     }
                //   });
                // }
              }}
            >
              <PayButtonContent
                showButtonLoader={showButtonLoader}
                setShowButtonLoader={setShowButtonLoader}
                buttonText={buttonText}
              />
            </Button>

            {/* <div className='text-micro font-medium text-grey-dark payment-price'>
              {priceResult}
            </div> */}
          </Box>
        </Box>
        <PartnerLegalInfo />
      </>
    )
  } else {
    // return <LoginOrRegister />
    return (<>
      <Button
        fullWidth
        disabled
        variant="outlined"
        color="inherit"
        sx={{ mt: 2 }}
      >
        {buttonText}
      </Button>
      <PartnerLegalInfo />
    </>)
  }
}

function PayButtonContent(props) {
  const { showButtonLoader, buttonText } = props
  if (!showButtonLoader) return <>{buttonText}</>

  return (
    <>
      <Box sx={{
        minHeight: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2,
      }}>
        Loading payment system <CircularProgress size={18} />
      </Box>
    </>
  )
}

function BusinessMotivation() {
  return (
    <>
      <Typography variant="subtitle1">
        Encrypt your company internet connection to protect your data and privacy
      </Typography>
      <Stack
        sx={{ mt: 1 }}
        spacing={1}
      >
        <Box display={'flex'}>
          <CheckIcon color="primary" fontSize="small" sx={{ mr: 1 }} />
          <Typography variant="subtitle2">
            Control unlimited number of accounts in one place
          </Typography>
        </Box>
        <Box display={'flex'}>
          <CheckIcon color="primary" fontSize="small" sx={{ mr: 1 }} />
          <Typography variant="subtitle2">
            Private high-speed servers
          </Typography>
        </Box>
        <Box display={'flex'}>
          <CheckIcon color="primary" fontSize="small" sx={{ mr: 1 }} />
          <Typography variant="subtitle2">
            Request custom features
          </Typography>
        </Box>
      </Stack>
    </>
  )
}

function TransactionSuccess() {
  const partnerSession = useRecoilValue(partnerSessionState)
  const returnURL = 'https://dingovpn.com/'//partnerSession?.successUrl || null

  return (
    <main>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        mt: 4,
        gap: 2,
      }}>
        <Typography variant="h5">
          Thank you for your purchase!
        </Typography>
        <Typography variant="body1">
          Your transaction was successful
        </Typography>
        {returnURL ? <Typography variant="body1">
          Return to <MuiLink
            component={Link}
            to={returnURL}
            // target='_blank'
            rel='noopener noreferrer'
          >
            partner website
          </MuiLink> and get your bonuses
        </Typography> : null}
      </Box>
    </main>
  )
}

function TransactionError() {
  const partnerSession = useRecoilValue(partnerSessionState)
  const returnURL = partnerSession?.successUrl || null

  return (
    <main>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        mt: 4,
        gap: 2,
      }}>
        <Typography variant="h5">
          Transaction failed
        </Typography>
        <Typography variant="body1">
          Please try again later
        </Typography>
        {returnURL ? <Typography variant="body1">
          Return to <MuiLink
            component={Link}
            to={returnURL}
            // target='_blank'
            rel='noopener noreferrer'
          >
            partner website
          </MuiLink> and try again
        </Typography> : null}
      </Box>
    </main>
  )
}



function openInNewTab(href) {
  Object.assign(document.createElement('a'), {
    target: '_blank',
    rel: 'noopener noreferrer',
    href: href,
  }).click();
}

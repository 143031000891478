import React from 'react'
import { Route, Outlet, Navigate } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  accountEmailState,
  tokenState,
} from '#state'
// 
import Login from '#page/Auth/Login'
import Register from '#page/Auth/Register'
import ResetPassword from '#page/Auth/ResetPassword'
import UpdatePassword from '#page/Auth/UpdatePassword'
import LoginOTC from '#page/Auth/LoginOTC'
import CheckOTC from '#page/Auth/CheckOTC'


export default function AccountRoutes(props) {
  // const { email, setEmail, setToken } = props
  const [email, setEmail] = useRecoilState(accountEmailState)
  const [token, setToken] = useRecoilState(tokenState)

  return (
    <>
      {/* redirect legacy routes */}
      <Route path='/login' element={<Navigate to='/account/login' replace={true} />} />
      <Route path='/register' element={<Navigate to='/account/register' replace={true} />} />
      <Route path='/reset' element={<Navigate to='/account/resetpass' replace={true} />} />
      <Route path='/resetcode' element={<Navigate to='/account/updatepass' replace={true} />} />
      <Route path='/codelogin' element={<Navigate to='/account/otclogin' replace={true} />} />
      <Route path='/otccode' element={<Navigate to='/account/otccheck' replace={true} />} />
      
      {/* account routes */}
      <Route path='/account/' element={<Outlet />}>
        <Route
          path='login'
          element={
            <Login
              email={email}
              setEmail={setEmail}
              setToken={setToken}
              root={'/hello'}
              to={'/hello'}
            />
          }
        />
        <Route
          path='register'
          element={
            <Register
              email={email}
              setEmail={setEmail}
              setToken={setToken}
              root={'/account/login'}
              to={'/account/login'}
            />
          }
        />
        <Route
          path='resetpass'
          element={
            <ResetPassword
              email={email}
              setEmail={setEmail}
              root={'/account/login'}
              to={'/account/login'}
            />
          }
        />
        <Route
          path='updatepass'
          element={
            <UpdatePassword
              email={email}
              setEmail={setEmail}
              setToken={setToken}
              root={'/account/login'}
              to={'/account/resetpass'}
            />
          }
        />
        <Route
          path='otclogin'
          element={
            <LoginOTC
              email={email}
              setEmail={setEmail}
              root={'/account/login'}
              to={'/account/login'}
            />
          }
        />
        <Route
          path='otccheck'
          element={
            <CheckOTC
              email={email}
              setEmail={setEmail}
              setToken={setToken}
              root={'/account/login'}
              to={'/account/otclogin'}
            />
          }
        />
      </Route>
    </>
  )
}

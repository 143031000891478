const locale = {
  languages: {
    en: 'English',
    ru: 'Russian',
    de: 'German',
    es: 'Spanish',
    fr: 'French',
    ja: 'Japanese'
  },
  hello: {
    title: 'Protect your privacy with a click',
    login: 'Log in',
    register: 'Create Dingo Account'
  },
  servers: {
    search: 'Search'
  },
  drawer: {
    vpn: 'VPN',
    settings: 'Settings',
    support: 'Support'
  },
  theme: {
    current: 'Current Theme',
    system: 'System',
    dark: 'Dark',
    light: 'Light'
  },
  paywall: {
    title: 'Dingo VPN Premium',
    upgradeButton: 'UPGRADE TO PREMIUM',
    titleModal: 'Subscribe to Premium now',
    subtitleModal: 'Get access to all premium features',
    subtitles: [
      'Absolutely NO ADS',
      'Blazing fast connection speed',
      'Military-grade encryption',
      'Unlock all virtual locations',
      'Tech support',
      'All upcoming features'
    ],
    awaiterText: 'Awaiting purchase server response',
    subscribeButton: 'START FREE TRIAL',
    subscribeButtonNoTrial: 'SUBSCRIBE NOW',
    trial: '3 days free trial then',
    noTrial: 'Auto-renewable subscription',
    terms: 'Terms of Use',
    privacy: 'Privacy Policy',
    restore: 'Restore Purchase'
  },
  settings: {
    title: 'Settings',
    language: 'Language',
    theme: 'Theme',
    themeDesc: 'Change the app appearance',
    account: 'Dingo Account',
    accountControl: 'Account Control',
    accountControlDesc: 'Open account control page',
    accountControlButton: 'Open',
    accountLogin: 'Log in',
    accountLogout: 'Log out',
    accountRemove: 'DELETE Account',
    subsControl: 'Subscriptions Control',
    startWithSystem: 'Start with System',
    startWithSystemDesc: 'Start DingoVPN with System startup',
    startHidden: 'Start Hidden',
    startHiddenDesc: 'Start DingoVPN minimized',
    tracking: 'Send anonymous usage data',
    trackingDesc: 'Allow us to improve our app'
  },
  account: {
    title: 'Dingo Account',
    "errors": {
      "invalid_password": "Incorrect email or password. Try again",
      "invalid_code": "Incorrect code. Try again later",
      "get_profile_error": "Incorrect email or password. Try again",
      "emptyEmail": "Email could not be empty!",
      "incorrectEmail": "Incorrect email address!",
      "emptyPass": "Password could not be empty!",
      "emptyCode": "Code could not be empty!",
      "invalid_promo_code": "Not valid promo code!",
    },
    login: {
      title: 'Log In',
      createPre: 'New user?',
      createLink: 'Create Dingo Account',
      emailLabel: 'Email address',
      emailPlaceholder: 'dingovpn@gmail.com',
      passLabel: 'Password',
      passPlaceholder: '***************',
      mainBtn: 'Continue',
      resetLink: 'Forgot your password?',
      otcBtn: 'Email me a login code'
    },
    register: {
      title: 'Register',
      loginPre: 'Already have an account?',
      loginLink: 'Log In',
      emailLabel: 'Email address',
      emailPlaceholder: 'dingovpn@gmail.com',
      passLabel: 'Password',
      passPlaceholder: '***************',
      mainBtn: 'Continue'
    },
    resetPass: {
      title: 'Reset Password',
      createPre: 'New user?',
      createLink: 'Create Dingo Account',
      emailLabel: 'Email address',
      emailPlaceholder: 'dingovpn@gmail.com',
      mainBtn: 'Send Code',
      resetLink: 'Already have Reset Code?',
      otcBtn: 'Email me a login code'
    },
    updatePass: {
      title: 'Update Password',
      createPre: 'New user?',
      createLink: 'Create Dingo Account',
      codeLabel: 'Reset Code',
      codePlaceholder: '***************',
      passLabel: 'New Password',
      passPlaceholder: '***************',
      mainBtn: 'Continue',
      resetLink: 'Do not get Reset Code?',
      otcBtn: 'Email me a login code'
    },
    loginSendCode: {
      title: 'Log in with code',
      createPre: 'New user?',
      createLink: 'Create Dingo Account',
      emailLabel: 'Email address',
      emailPlaceholder: 'dingovpn@gmail.com',
      mainBtn: 'Continue',
      otcLink: 'Already have Login Code?',
      loginBtn: 'Log in by password'
    },
    loginCheckCode: {
      title: 'Log in with code',
      createPre: 'New user?',
      createLink: 'Create Dingo Account',
      emailLabel: 'Email address',
      emailPlaceholder: 'dingovpn@gmail.com',
      codeLabel: 'Login Code',
      codePlaceholder: '123456',
      mainBtn: 'Continue',
      otcLink: 'Do not get Login Code?',
      loginBtn: 'Log in by password'
    },
    remove: {
      title: 'Remove Account',
      message:
        'Are you sure that you really want to permanently delete your Dingo account?\nThis action will be irreversible!\nYou may need to manually cancel your subscription after deletion.',
      cancelBtn: 'Cancel',
      removeBtn: 'Email me remove link'
    }
  },
  "location": {
    region: {
      Dev: "Dev",
      Auto: 'Quick Connect',
      Favorites: 'Favorites',
      Latest: 'Latest',
      Free: 'Free',
      World: 'World',
      Americas: 'Americas',
      Europe: 'Europe',
      Asia: 'Asia',
      AsiaPacific: 'Asia-Pacific',
      Australia: 'Australia'
    },
    country: {
      CA: 'Canada',
      MX: 'Mexico',
      SE: 'Sweden',
      US: 'United States',
      NL: 'Netherlands',
      DE: 'Germany',
      GB: 'United Kingdom',
      FR: 'France',
      IN: 'India',
      KR: 'South Korea',
      SG: 'Republic of Singapore',
      JP: 'Japan',
      AU: 'Australia',
      RU: 'Russia',
      FI: 'Finland',
      HK: 'Hong Kong',
      CH: 'Switzerland',
      ES: 'Spain',
      PT: 'Portugal',
      MD: 'Moldova',
      IE: 'Ireland',
      RO: 'Romania',
      HU: 'Hungary',
      CZ: 'Czech Republic',
      LV: 'Latvia',
      UA: 'Ukraine',
      IL: 'Israel',
      TR: 'Turkey',
      PL: 'Poland',
      BG: 'Bulgaria',
      KZ: 'Kazakhstan',
      RS: 'Serbia',
      SK: 'Slovakia',
      IT: 'Italy',
      GR: 'Greece',
    },
    city: {
      dev: 'Dev MSK',
      auto_fastest: 'Fastest Location',
      auto_nearest: 'Nearest Country',
      fr_paris_free: 'Paris',
      us_dallas_free: 'Dallas, TX',
      de_frankfurt_free: 'Frankfurt',
      us_ny_free: 'New York, NY',
      ru_moscow_free: 'Moscow',
      auto: 'Auto',
      ca_toronto: 'Toronto',
      se_stockholm: 'Stockholm',
      mx_mexico: 'Mexico City',
      us_atlanta: 'Atlanta, GA',
      us_chicago: 'Chicago, IL',
      us_dallas: 'Dallas, TX',
      us_la: 'Los Angeles, CA',
      us_miami: 'Miami, FL',
      us_ny: 'New York, NY',
      us_sf: 'San Francisco, CA',
      us_seattle: 'Seattle, WA',
      us_sv: 'Silicon Valley, CA',
      nl_amsterdam: 'Amsterdam',
      de_frankfurt: 'Frankfurt',
      gb_london: 'London',
      fr_paris: 'Paris',
      in_bengaluru: 'Bengaluru',
      kr_seoul: 'Seoul',
      sg_singapore: 'Singapore',
      jp_tokio: 'Tokio',
      au_sydney: 'Sydney',
      ru_moscow: 'Moscow',
      gb_coventry: 'Coventry',
      fi_helsinki: 'Helsinki',
      us_secaucus: 'Secaucus, NJ',
      hk_hongkong: 'Hong Kong',
      nl_meppel: 'Meppel',
      ch_zug: 'Zug',
      es_madrid: 'Madrid',
      pt_lisbon: 'Lisbon',
      md_chisinau: 'Chisinau',
      ie_dublin: 'Dublin',
      ro_bucharest: 'Bucharest',
      hu_budapest: 'Budapest',
      cz_veseli: 'Veseli nad Luznici',
      lv_riga: 'Riga',
      ua_kyiv: 'Kyiv',
      il_tel_aviv: 'Tel Aviv-Yafo',
      tr_izmir: 'Izmir',
      pl_warsaw: 'Warsaw',
      bg_sofia: 'Sofia',
      kz_almaty: 'Almaty',
      rs_belgrade: 'Belgrade',
      sk_bratislava: 'Bratislava',
      it_rome: 'Rome',
      gr_thessaloniki: 'Thessaloniki',
      us_secaucus_free: 'Secaucus, NJ',
      ca_beauharnois: 'Beauharnois',
      us_ashburn: 'Ashburn, VA',
    }
  }
}

export default locale

import React, { useEffect, useState } from 'react'
import ReactSlider from 'react-slider'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import { Box, CardActionArea } from '@mui/material';
import SelectDot from './SelectDot'
import { PancakeRates } from './Yeti'
import './CustomPlan.css'
import {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil'
import {
  appearanceState,
  customPlanMonthsState,
} from '#state'


export function CustomPlan(props) {
  const {
    showBest,
    plans,
    selectedPlanID,
    setSelectedPlanID,
    selectedPlan
  } = props

  // const info = usePlanInfo(props)

  return (
    <Stack
      direction="row"
      spacing={{ xs: 0, sm: 1 }}
      sx={{
        mt: 1,
        mb: 2,
        flexDirection: { xs: 'column', sm: 'row' },
        justifyContent: 'space-between',
        alignItems: 'stretch',
        flexWrap: 'wrap',
      }}
    >
      <Plan {...props} />
      {/* <YetiPaymentOffer {...info} /> */}
      {/* <CustomServer /> */}
    </Stack>
  )
}

function usePlanInfo(props) {
  const {
    selectedPlanID,
    setSelectedPlanID,
    plans,
    selectedPlan
  } = props

  const [customPlanMonths, setCustomPlanMonths] = useRecoilState(customPlanMonthsState)

  let months = customPlanMonths

  useEffect(() => {
    if (plans && plans.custom) {
      setCustomPlanMonths(plans.custom.monthsDefault)
    }
  }, [plans])

  switch (selectedPlanID) {
    case 'month':
      months = 1
      break
    case 'year':
      months = 12
      break
    case '2years':
      months = 24
      break
    default:
      months = customPlanMonths
      break
  }
  const { BNB, YETI, priceBNB, priceYETI } = PancakeRates({ selectedPlan, customPlanMonths })
  const offerPriceYETI = priceYETI * 0.6
  const monthlyPriceYETI = (offerPriceYETI / months).toFixed(2)

  if (!plans || !selectedPlanID) return {}

  const plan = plans.custom

  // console.log('plan', plan, plans)
  const selected = selectedPlanID === plan?.id
  // const savePercent = plan.monthSave * (customPlanMonths - 1)
  let savePercent = 0
  for (let i = 0; i < customPlanMonths; i++) {
    savePercent += plan.monthSave[i]
  }
  // const resultPrice = 1 * customPlanMonths * Math.E^(-customPlanMonths/24)
  const resultPrice =
    (plan.priceMonthUSD * customPlanMonths * (100 - savePercent)) / 100

  const fullPrice = plan.priceMonthUSD * customPlanMonths
  const monthlyPrice = (resultPrice / customPlanMonths).toFixed(2)

  let selectedFull = fullPrice
  let selectedResult = resultPrice
  if (!selected) {
    selectedFull = selectedPlan.priceFull && +selectedPlan.priceFull.slice(1) || +selectedPlan.price.slice(1)
    selectedResult = +selectedPlan.priceResult.slice(1)
  }

  const yetiProps = {
    plan,
    selected,
    savePercent,
    monthlyPrice,
    fullPrice,
    resultPrice,
    offerPriceYETI,
    monthlyPriceYETI,
    priceYETI,
    selectedResult
  }

  return yetiProps
}

export function Plan(props) {
  const {
    selectedPlanID,
    setSelectedPlanID,
    plans,
    selectedPlan
  } = props

  const appearance = useRecoilValue(appearanceState)
  const [customPlanMonths, setCustomPlanMonths] = useRecoilState(customPlanMonthsState)

  const info = usePlanInfo(props)
  const {
    plan,
    selected,
    savePercent,
    monthlyPrice,
    fullPrice,
    resultPrice
  } = info

  if (!selectedPlanID || !plans) {
    return (
      <Card
        elevation={selected ? 3 : 0}
        sx={{
          minHeight: 180,
          backgroundFilter: 'blur(2px)',
          backgroundColor: '#ff562f11',
          mt: { xs: 1, sm: 0 },
          flex: { xs: '1 1 80%', sm: '1 1 30%', },
          border: '1px solid #77777755',
          borderColor: selected ? '#ff562f' : '#ff562f77'
        }}
        onClick={() => {
          setSelectedPlanID(plan.id)
        }}
      >
        <Skeleton variant="rounded" width={'100%'} height={'100%'} />
      </Card>
    )
  }


  return (
    <Card
      elevation={selected ? 3 : 0}
      sx={{
        backgroundFilter: 'blur(2px)',
        backgroundColor: '#ff562f11',
        mt: { xs: 1, sm: 0 },
        flex: { xs: '1 1 80%', sm: '1 1 30%', },
        border: '1px solid #77777755',
        borderColor: selected ? '#ff562f' : '#ff562f77'
      }}
      onClick={() => {
        setSelectedPlanID(plan.id)
      }}
    >
      <CardActionArea sx={{
        height: '100%',
      }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Box sx={{
            display: 'flex',
            width: 1,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
            <Stack direction={{ xs: 'column', sm: 'row' }}>
              <Typography variant="h5" mr={1}>
                {plan.title}
              </Typography>
              <Typography variant="h5" sx={{
                mr: 1,
                display: { xs: 'none', sm: 'block' }
              }}>
                {' | '}
              </Typography>
              <Typography variant="h5">
                {`${customPlanMonths} ${customPlanMonths > 1 ? 'months' : 'month'}`}
              </Typography>
            </Stack>

            <Chip
              label={`Save ${savePercent}%`}
              variant={selected ? 'default' : 'outlined'}
              color={selected ? 'primary' : 'default'}
              sx={{
                backgroundColor: selected ? '#ff562f' : 'default',
                borderColor: '#ff562f',
                color: selected ? '#ffffff' : '#ff562f'
              }}
            />
          </Box>
          <Typography
            variant="h4"
            color="text.primary"
            sx={{
              mb: 1,
              fontWeight: 500
            }}
          >
            ${monthlyPrice}/mo
          </Typography>

          <ReactSlider
            marks
            className={`horizontal-slider CustomPlan-${appearance}`}
            thumbClassName='purchase-thumb'
            trackClassName='purchase-track'
            markClassName='purchase-mark'
            min={0}
            max={23}
            value={customPlanMonths - 1}
            defaultValue={plan.monthsDefault - 1}
            onBeforeChange={(value, index) => {
              if (!selected) {
                setSelectedPlanID(plan.id)
              }
              console.log(
                `onBeforeChange: ${JSON.stringify({
                  value,
                  index
                })}`
              )
            }}
            onChange={(value, index) => {
              // console.log(`ReactSlider onChange: value ${value} index ${index}`)
              console.log(`ReactSlider setCustomPlanMonths ${value + 1}`)
              setCustomPlanMonths(value + 1)
            }}
            onAfterChange={(value, index) =>
              console.log(
                `onAfterChange: ${JSON.stringify({ value, index })}`
              )
            }
            renderTrack={(props, state) => <div {...props} />}
            renderThumb={(props, state) => (
              <div {...props}>{state.valueNow + 1}</div>
            )}
            renderMark={props => <div {...props} />}
          />

          <Typography variant="body1" color="text.secondary">
            {plan.priceMonthUSD ? (
              <del style={{ marginRight: '4px' }}>
                ${fullPrice.toFixed(2)}
              </del>
            ) : null}
            <Typography
              variant="body1"
              component="span"
              color="info.main"
            >
              ${resultPrice.toFixed(2)}{' '}
            </Typography>
            {plan.periodDesc} *
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

function YetiPaymentOffer(props) {
  const {
    offerPriceYETI,
    monthlyPriceYETI,
    priceYETI,
    plan,
    selectedResult
  } = props

  return (<div className='yeti-col col-12 md:col-12 lg:col-4 flex flex-col mb-3'>
    <button
      type='button'
      id='yeti-offer'
      className={
        'focus:outline-none flex w-full px-3 py-6 md:px-4 text-grey-darkest rounded-2 border-2 shadow-1 bg-white border-white transition-all duration-250 ease-out  text-left flex-auto border-red'
      }
      onClick={() => {
        // setSelectedPlanID(plan.id)
      }}
    >
      <div className='flex flex-col justify-between self-stretch w-full font-medium yeti-offer-text'>
        <div>
          <div className='flex flex-wrap justify-between items-center'>
            <div className='text-base lg:text-lead mb-2 mr-2'>
              LIMITED TIME OFFER
            </div>
            <div>
              <div
                className={
                  'px-3 py-1 rounded-3 inline-block text-micro font-medium transition-colors duration-250 ease-out mb-2 bg-red text-white'
                }
              >
                Save 40% More
              </div>
            </div>
          </div>
          <div className='text-base lg:text-base mb-2 mr-2'>
            Pay with{' '}
            <a
              className='yeti-link cursor-pointer outline-none transition-colors duration-250 ease-out text-grey-dark hover:text-grey-darker text-blue leading-loose'
              target='_blank'
              rel='noreferrer'
              href='https://yeticoin.tech/'
            >
              YETI Coin
            </a>{' '}
            and save 40% more!
          </div>
          <div data-testid='ProductCard__price' className='mb-2'>
            <span data-testid='ProductPrice' className='price'>
              <span className='yeti-text text-base leading-normal text-h4 leading-loose'>
                {monthlyPriceYETI} YETI
              </span>
            </span>
            <span className='text-h4 lg:text-base'>/mo</span>
          </div>
        </div>
        <p className='text-micro font-medium text-grey-dark'>
          {' '}
          {offerPriceYETI ? (
            <del>{priceYETI.toFixed(2)}</del>
          ) : null}
          <span className='text-blue'>
            {+(offerPriceYETI).toFixed(4)} YETI
          </span>{' '}<span >
            (~${(selectedResult * 0.6).toFixed(2)})
          </span>{' '}
          {plan.periodDesc}
          <span className='text-small' data-testid='IntroductorySymbol'>
            *
          </span>
        </p>
      </div>
    </button>
  </div>)
}


function CustomServer() {
  return (
    <div className='yeti-col col-12 md:col-12 lg:col-4 flex flex-col mb-3'>
      <button
        type='button'
        className={
          'focus:outline-none flex w-full px-3 py-6 md:px-4 text-grey-darkest rounded-2 border-2 shadow-1 bg-white border-white transition-all duration-250 ease-out  text-left flex-auto'
        }
        onClick={() => {
          // setSelectedPlanID(plan.id)
        }}
      >
        <SelectDot selected={false} />
        <div className='flex flex-col justify-between self-stretch w-full font-medium yeti-offer-text'>
          <div>
            <div className='flex flex-wrap justify-between items-center'>
              <div className='text-base lg:text-lead mb-2 mr-2'>
                Your Private Server
              </div>
              <div>
                <div
                  className={
                    'px-3 py-1 rounded-3 inline-block text-micro font-medium transition-colors duration-250 ease-out mb-2 bg-grey-lighter text-grey-darker'
                  }
                >
                  COMING SOON
                </div>
              </div>
            </div>
            <div className='text-base lg:text-base mb-2 mt-4 mr-2'>
              Server in selected location exclusively for you!
            </div>
            <div className='text-base lg:text-lead mb-2 mt-4 mr-2'>
              Maximum speed and stability!
            </div>
            <div data-testid='ProductCard__price' className='mb-2'>
              <span data-testid='ProductPrice' className='price'>
                {/* <span className='yeti-text text-base text-h5 leading-loose'>
                    COMING SOON
                  </span> */}
              </span>
            </div>
          </div>

        </div>
      </button>
    </div>
  )
}
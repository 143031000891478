import React, { useEffect, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
// import { useTranslation } from 'react-i18next'
// mui
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'
// import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
// icons
import StarIcon from '@mui/icons-material/Star'
import StarOutlineIcon from '@mui/icons-material/StarOutline'
// modules
import Flag from './Flag'
import Search from './Search'
import location from '../locations'
import { useRecoilValue } from 'recoil'
import { appearanceState } from '#state'

const favouriteServerIDs = []
const setFavouriteServerIDs = () => { }
const latestServerIDs = []

export default function ServersWrapper(props) {
  const {
    servers,
    selectedServer,
    setSelectedServer,
    // favouriteServerIDs,
    // setFavouriteServerIDs,
    // latestServerIDs
  } = props

  // const { t } = useTranslation()
  const [serversPlane, setServersPlane] = useState([])
  const [favourites, setFavourites] = useState([])
  const [serversFiltered, setServersFiltered] = useState(servers)

  useEffect(() => {
    const plane = []
    const fav = []
    servers.map(item => {
      const srvs = item.data.map(srv => {
        const isFavorite = false //favouriteServerIDs.includes(srv.id)
        const localName = location.city[srv.id] || srv.id //t(`location.city.${srv.id}`)
        const country = location.country[srv.countryCode] || srv.countryCode //t(`location.country.${srv.countryCode}`)
        const enName = location.city[srv.id] || srv.id //t(`location.city.${srv.id}`, { lng: 'en' })
        const enCountry = location.country[srv.countryCode] || srv.countryCode //t(`location.country.${srv.countryCode}`, { lng: 'en' })
        const info = {
          ...srv,
          isFavorite,
          region: item.region,
          country,
          enCountry,
          localName,
          enName
        }
        plane.push(info)
        if (isFavorite) {
          fav.push(info)
        }
        return info
      })
      return {
        region: item.region,
        data: srvs
      }
    })

    setServersPlane(plane)
    setFavourites(fav)
    // console.log('serversPlane', plane)
  }, [servers, favouriteServerIDs])

  return (
    <Box sx={{ flexGrow: 1, px: 1 }} className='serversWrapper'>
      <Search
        servers={serversPlane}
        favourites={favourites}
        setServersFiltered={setServersFiltered}
        latestServerIDs={latestServerIDs}
      />

      <Regions
        regions={serversFiltered}
        favouriteServerIDs={favouriteServerIDs}
        setFavouriteServerIDs={setFavouriteServerIDs}
        selectedServer={selectedServer}
        setSelectedServer={setSelectedServer}
      />
    </Box>
  )
}

function Regions(props) {
  const {
    regions,
    selectedServer,
    setSelectedServer,
    favouriteServerIDs,
    setFavouriteServerIDs
  } = props
  // const { t } = useTranslation()
  const appearance = useRecoilValue(appearanceState)
  const [hovered, setHovered] = useState(null)
  // console.log('Regions', regions)
  const Regions = regions.map((item, i) => {
    const regionLocalized = location.region[item.region] || item.region //t(`location.region.${item.region}`)

    if (item.region === 'Favorites' && !favouriteServerIDs.length) {
      return null
    }

    return (
      <Grid
        key={i}
        container
        // direction="column"
        alignItems='center'
        justifyContent='flex-start'
        sx={{
          // overflowX: 'hidden',
        }}
      >
        <Grid
          item
          container
          xs
          justifyContent='center'
          className='regionHeader'
        >
          <Typography variant='button'>{regionLocalized}</Typography>
        </Grid>
        <Grid item xs={12} sx={{
          flexBasis: 1,
          flex: '1 1 100%',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}>
          <Locations
            region={item.region}
            servers={item.data}
            favouriteServerIDs={favouriteServerIDs}
            setFavouriteServerIDs={setFavouriteServerIDs}
            selectedServer={selectedServer}
            setSelectedServer={setSelectedServer}
            hovered={hovered}
            setHovered={setHovered}
          />
        </Grid>
      </Grid>
    )
  })

  return (
    <Stack
      spacing={1}
      sx={{
        p: 1,
        flex: '1 1 100%',
        maxHeight: '50vh',
        overflowY: 'auto',
        overflowX: 'hidden'
      }}
      className='serversList cscroll'
    >
      <OverlayScrollbarsComponent
        options={{
          scrollbars: {
            theme: appearance === 'dark' ? 'os-theme-light': 'os-theme-dark',
            autoHide: 'scroll'
          }
        }}
        defer
      >
        {Regions}
      </OverlayScrollbarsComponent>
    </Stack>
  )
}

function Locations(props) {
  const {
    servers,
    selectedServer,
    setSelectedServer,
    favouriteServerIDs,
    setFavouriteServerIDs,
    hovered,
    setHovered,
    region
  } = props

  // const { t } = useTranslation()
  const theme = useTheme()

  // console.log('Locations', servers)
  // console.log('selectedServer', selectedServer)
  const Locations = servers.map((item, i) => {
    // const freeBadgeTextLocal = locale.vpn.freeBadge
    const localName = location.city[item.id] || item.id //t(`location.city.${item.id}`)
    const country = location.country[item.countryCode] || item.countryCode //t(`location.country.${item.countryCode}`)
    const isSelected = selectedServer && selectedServer.id === item.id
    // console.log('Location', localName,'isSelected', isSelected)
    const isHovered = hovered === `${region}-${item.id}`

    const locationMotion = {
      initial: {
        opacity: 0,
        // backgroundColor: 'white'
      },
      animate: {
        opacity: 1,
        scale: isHovered ? 0.97 : 1,
        backgroundColor: isSelected
          ? theme.palette.action.selected
          : isHovered
            ? theme.palette.action.hover
            : 'rgba(0,0,0,0)'
      },
      exit: { opacity: 0 },
      tap: {
        scale: 0.95,
        backgroundColor: theme.palette.action.focus
      },
      hover: {
        scale: 0.97,
        backgroundColor: theme.palette.action.hover
      }
    }

    return (
      <AnimatePresence key={i}>
        <Grid
          key={`srv-${i}`}
          item
          container
          alignItems='space-between'
          justifyContent='center'
          sx={{
            p: 1,
            flex: '1 1 100%',
            overflow: 'hidden'
          }}
          component={motion.div}
          variants={locationMotion}
          initial='initial'
          animate='animate'
          exit='exit'
          // whileHover='hover'
          whileTap='tap'
          onHoverStart={() => {
            // fix for hover bug (then element leaves mouse position onHoverEnd do not trigger)
            setHovered(`${region}-${item.id}`)
          }}
          onHoverEnd={() => {
            setHovered(null)
          }}
          onClick={() => {
            console.log('set selected server', item)
            setSelectedServer(item)
          }}
        >
          <Grid item container xs={1} sx={{ mr: 1 }} alignItems='center'>
            <Flag country={item.countryCode || 'un'} size={24} />
          </Grid>
          <Grid item container xs alignItems='center'>
            <Typography variant='body1'>
              {item.countryCode ? `${localName}, ${country}` : localName}
            </Typography>
          </Grid>
        </Grid>
      </AnimatePresence>
    )
  })

  return (
    <Stack spacing={0} sx={{}}>
      {Locations}
    </Stack>
  )
}

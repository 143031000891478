import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import FuzzySearch from 'fuzzy-search'
import { isSubscribedCheck } from '../utils'
import * as requests from '#src/requests'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  accountState,
  tokenState,
  isSubscribedSelector,
} from '#state'

const emailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/

export default function Users (props) {
  const { account, token } = props
  const isSubscribed = useRecoilValue(isSubscribedSelector)
  let selectedPlan = null

  const connectedAccounts = (account && account.connectedAccounts) || []

  return (
    <div className='yeti-container container text-black flex-grow flex-shrink-0'>
      <div className='Card2 smd:shadow-1 smd:rounded bg-white smd:p-1 smd:mx-0 smd:pb-6 smd:pt-8 mx-auto mt-6 smd:mt-12 smd:px-6 flex flex-col p-6'>
        <h2 className='yeti-text text-h4 leading-normal font-medium tracking-tight mb-6 text-center'>
          Connected Users
        </h2>
        <UsersList connectedAccounts={connectedAccounts} token={token} />
      </div>
    </div>
  )
}

function UsersList ({ connectedAccounts, token }) {
  const now = Date.now()
  const [showErrors, setShowErrors] = useState(false)
  const [showInvitePanel, setShowInvitePanel] = useState(false)
  const [emailError, setEmailError] = useState('')
  const [email, setEmail] = useState('')

  const searchInput = useRef(null)
  const [input, setInput] = useState('')
  const [filtered, setFiltered] = useState(connectedAccounts)

  useEffect(() => {
    const searcher = new FuzzySearch(connectedAccounts, ['email'], {
      caseSensitive: false
    })
    const result = searcher.search(input)
    setFiltered(result)
  }, [connectedAccounts, input])

  useEffect(() => {
    if (email === '') {
      setEmailError('Email could not be empty!')
    } else if (!email.match(emailformat)) {
      setEmailError('Incorrect email address!')
    } else {
      setEmailError('')
    }
  }, [email])

  const inviteCheck = async event => {
    event.preventDefault()

    if (emailError) {
      console.log('email incorrect')
      setShowErrors(true)
    } else {
      const result = await requests.businessInvite(email, token)
      if (!result.success) {
        console.log('invite error', result)
        if (result.error) {
          setShowErrors(true)
          setEmailError(result.error)
        } else {
          setEmailError('')
        }
      } else {
        setShowErrors(false)
        console.log('invite success', result)
        // setToken(result.token)
        // try {
        //   await localforage.setItem('token', result.token)
        // } catch (err) {
        //   console.log('save token error', err)
        // }
        // navigate('/')
      }
    }
  }

  if (!connectedAccounts || (connectedAccounts && !connectedAccounts.length))
    return null

  const users = filtered.map((user, i) => {
    const date = new Date(user.connectedAt)
    return (
      <tr className='connectedUserRow md:tr sm:flex' key={i}>
        <td className='connectedUserEmail'>{user.email}</td>
        <td className='connectedUserRole'>{user.role}</td>
        <td className='connectedUserDate'>{`${date.getUTCDate()}.${date.getUTCMonth()}.${date.getUTCFullYear()}`}</td>
        <td className='connectedUserActions'>
          <button
            className='connectedUserRemove yeti-button button--third user-action'
            onClick={async () => {
              const result = await requests.businessRemoveUser(user.id, token)
              console.log('businessRemoveUser result', user, result)
              // onConfirm()
              // setAccount(null)
              // navigate('/')
            }}
          >
            Remove
          </button>
        </td>
      </tr>
    )
  })

  return (
    <div className='yeti-col col justify-center align-center mb-3'>
      {/* <div className='font-medium leading-normal w-full mt-6 mb-6 text-center'>
        UsersList
      </div> */}
      <div className='yeti-input__wrapper flex w-full justify-center align-center mb-3'>
        <div className='yeti-input_line sm:w-full md:w-auto yeti-search smd:mr-3 sm:mr-1'>
          <input
            ref={searchInput}
            autoComplete='off'
            className='yeti-input sm:w-full md:w-auto border rounded align-bottom leading-normal outline-none appearance-none p-3 InputField bg-transparent text-grey-darker border-grey'
            type='text'
            name='filter'
            placeholder='Search user by email'
            value={input}
            onChange={event => {
              setInput(event.target.value)
              // const result = searcher.search(event.target.value)
              // setFiltered(result)
              // console.log('input', event.target.value, 'result', result)
            }}
          />
        </div>
        <div className='connectedUserActions'>
          <button
            className='connectedUserInvite yeti-button px-3 focus:outline-none font-medium leading-normal align-bottom rounded select-none transition-colors ease-out duration-250 cursor-pointer border text-white yeti-button--v-contained yeti-button--c-blue bg-blue border-blue'
            onClick={async () => {
              // const result = await requests.cancelSubscription(subId, token, reason)
              console.log('connectedUserInvite ')
              setShowInvitePanel(!showInvitePanel)
              // onConfirm()
              // setAccount(null)
              // navigate('/')
            }}
          >
            Add User
          </button>
        </div>
      </div>
      <div className={`invite mb-3 ${showInvitePanel ? '' : 'hidden'}`}>
        <div className='connectedUserEmail sm:w-full md:w-auto'>
          <input
            className={
              'yeti-input inviteEmail sm:w-full border rounded w-full align-bottom leading-normal outline-none appearance-none p-3 InputField bg-transparent text-grey-darker InputField__email ' +
              (showErrors && emailError ? 'border-red' : 'border-grey')
            }
            type='email'
            id='identifier_field'
            name='identifier'
            placeholder='Email address'
            value={email}
            onChange={event => {
              setEmail(event.target.value)
            }}
          />
          <p
            className={
              'yeti-text flex justify-center w-full text-center text-micro leading-normal mt-3 flex leading-none text-red ' +
              (showErrors && emailError ? '' : 'hidden')
            }
          >
            {emailError}
          </p>
        </div>
        <div className='connectedUserRole'>
          <select
            id='role-select'
            className='role-select border rounded outline-none'
          >
            {/* <option value="">--Please choose an option--</option> */}
            <option value='user'>User</option>
            <option value='manager'>Manager</option>
          </select>
        </div>
        <div className='connectedUserActions'>
          <button
            className='connectedUserInvite yeti-button focus:outline-none font-medium leading-normal rounded select-none transition-colors ease-out duration-250 cursor-pointer border bg-transparent yeti-button--v-outlined yeti-button--c-grey text-grey-darker border-grey block text-grey-darkest text-small text-center'
            onClick={async event => {
              const result = await inviteCheck(event)
              console.log('connectedUserInvite result', result)
              // onConfirm()
              // setAccount(null)
              // navigate('/')
            }}
          >
            Invite User
          </button>
        </div>
      </div>

      <table className='connectedUsersTable sm:w-full md:w-auto mb-8'>
        <thead>
          <tr className='connectedUserHeaderRow sm:flex md:tr'>
            <th>Email</th>
            <th>Role</th>
            <th>ConnectedAt</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>{users}</tbody>
        <tfoot>
          <tr className='connectedUserFooterRow sm:flex md:tr'>
            <th>Accounts</th>
            <td>{connectedAccounts.length}</td>
            <td></td>
            <td></td>
          </tr>
        </tfoot>
      </table>
    </div>
  )
}

function Cancel ({ account, plans, selectedPlan }) {
  if (account.subStatus.status === 'ACTIVE') {
    if (account.paymentInfo.source === 'paddle') {
      return (
        <div className='yeti-row row flex  justify-around'>
          <Link
            className='yeti-button p-3 focus:outline-none font-medium leading-normal align-bottom rounded select-none transition-colors ease-out duration-250 cursor-pointer border text-white yeti-button--v-contained yeti-button--c-blue bg-blue border-blue'
            to='/cancel'
          >
            Cancel Subscription
          </Link>
        </div>
      )
    } else if (account.paymentInfo.source === 'inapp') {
      return null
    }
  } else {
    const nextPaymentTime = account.subStatus.nextPaymentTime
    return (
      <div className='font-medium leading-normal w-full mt-6 mb-6 text-center'>
        Your subscription is cancelled, but will be active untill{' '}
        {nextPaymentTime}
      </div>
    )
  }
}

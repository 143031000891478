import React from 'react';
import { Box, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles'
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useRecoilValue } from 'recoil'

import Header from '#rc/Header';
import Footer from '#rc/Footer';
import { appearanceState } from '#state'

const Layout = ({ children }) => {
  
  const theme = useTheme()
  const appearance = useRecoilValue(appearanceState)

  return (
    <Box
      sx={{
        minHeight: '100vh',
        // overflowY: 'auto',
        // overflowX: 'hidden'
        backgroundImage: `url(${theme.images.map})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    >
      <OverlayScrollbarsComponent
        options={{
          scrollbars: {
            theme: appearance === 'dark' ? 'os-theme-light' : 'os-theme-dark',
            autoHide: 'scroll'
          }
        }}
        defer
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            maxHeight: '100vh',
          }}
        >

          <Header />

          <Box
            component="main"
            sx={{
              flexGrow: 1,
              mt: 8,
              flex: '1 1 100vh',
              padding: (theme) => theme.spacing(1),
            }}
          >

            <Container maxWidth="lg" sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}>
              {children}
            </Container>
          </Box>

          <Footer />
        </Box>
      </OverlayScrollbarsComponent>
    </Box>
  );
};

export default Layout;

import React, { useEffect, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { motion, AnimatePresence } from 'framer-motion'
import localforage from 'localforage'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { AccountWrapper, Field, SecureField, Awaiter } from './components'
import * as requests from '#src/requests'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  accountEmailState,
  tokenState,
  clickIdState
} from '#state'

const emailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

export default function Register(props) {
  // const { setToken } = props
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [token, setToken] = useRecoilState(tokenState)
  const [email, setEmail] = useRecoilState(accountEmailState)
  const clickId = useRecoilValue(clickIdState)

  const [pass, setPass] = useState('')
  const [loginError, setLoginError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [showErrors, setShowErrors] = useState(false)
  const [isAwaiting, setIsAwaiting] = useState(false)

  useEffect(() => {
    if (email === '') {
      const errMessage = t('account.errors.emptyEmail')
      setLoginError(errMessage)
    } else if (!email.match(emailformat)) {
      const errMessage = t('account.errors.incorrectEmail')
      setLoginError(errMessage)
    } else {
      setLoginError('')
    }
    // check password
    if (pass === '') {
      const errMessage = t('account.errors.emptyPass')
      setPasswordError(errMessage)
    } else {
      setPasswordError('')
    }
  }, [email, pass])

  const check = async event => {
    // app root reload \ rerender fix
    event.preventDefault()
    if (loginError || passwordError) {
      console.log('login or pass incorrect')
      setShowErrors(true)
    } else {
      setIsAwaiting(true)
      const result = await requests.register(email, pass, clickId)
      setIsAwaiting(false)
      if (!result.success) {
        console.log('register error', result)
        if (result.error) {
          setShowErrors(true)
          const errMessage = t(`account.errors.${result.error}`)
          console.log('errMessage', errMessage)
          setPasswordError(errMessage)
        } else {
          setPasswordError('')
        }
      } else {
        setShowErrors(false)
        console.log('register success', result)
        setToken(result.token)
        try {
          await localforage.setItem('token', result.token)
        } catch (err) {
          console.log('save token error', err)
        }
        navigate('/')
        
        if (window.gtag) { 
          window.gtag('event', 'conversion', {
            'send_to': 'AW-10986044252/ozRECPrz0ZYYENz2xvYo',
            'event_callback': () => { 
              console.log('registration_callback')
            }
          });
          window.gtag('event', 'registration', {
            'event_callback': () => {
              console.log('registration_callback')
            }
          });
        }
        // trackEvent('Login')
        // navigate('/', { replace: true })
      }
    }
  }

  return (
    <>
      <AccountWrapper>
        <Grid
          component='form'
          container
          direction='column'
          alignItems='center'
          justifyContent='space-evenly'
          sx={{
            px: 5,
            py: 5,
            borderRadius: 2,
            // border: '1px solid',
            // borderColor: 'action.selected'
          }}
          onSubmit={check}
        >
          <Grid
            item
            container
            direction='column'
            alignItems='center'
            justifyContent='space-between'
            sx={{
              minHeight: '15vh'
            }}
          >
            <Typography textAlign='center' variant='h4'>{t('account.register.title')}</Typography>

            <Grid item container alignItems='center' justifyContent='center'>
              <Typography>{t('account.register.loginPre')}</Typography>
              <Button
                // size='small'
                sx={{ textTransform: 'none', cursor: 'pointer' }}
                onClick={() => {
                  navigate('/account/login', { replace: true })
                }}
              >
                <Typography>{t('account.register.loginLink')}</Typography>
              </Button>
            </Grid>
          </Grid>

          <Grid
            item
            container
            direction='column'
            alignItems='stretch'
            justifyContent='space-evenly'
            sx={{
              minHeight: '26vh'
            }}
          >
            <Field
              value={email}
              setValue={setEmail}
              label={t('account.register.emailLabel')}
              placeholder={t('account.register.emailPlaceholder')}
              errorMessage={loginError}
              showErrors={showErrors}
            />
            <SecureField
              value={pass}
              setValue={setPass}
              label={t('account.register.passLabel')}
              placeholder={t('account.register.passPlaceholder')}
              type='password'
              autoComplete='current-password'
              errorMessage={passwordError}
              showErrors={showErrors}
              secureTextEntry
            />
          </Grid>
          <Grid
            item
            container
            direction='column'
            alignItems='center'
            justifyContent='space-between'
            sx={{
              minHeight: '14vh'
            }}
          >
            <Button
              fullWidth
              variant='contained'
              size='large'
              type="submit"
            >
              <Typography>{t('account.register.mainBtn')}</Typography>
            </Button>
          </Grid>
        </Grid>
      </AccountWrapper>
      <Awaiter isAwaiting={isAwaiting} />
    </>
  )
}

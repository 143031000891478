import React, { useEffect, useState, useRef } from 'react'
import { QRCodeCanvas } from 'qrcode.react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Container, Box, Grid, Button, Typography, Skeleton } from '@mui/material';

import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'
import { useTheme } from '@mui/material/styles'

import Flag from '../Servers/Flag'
import * as requests from '#src/requests'
import './configs.css'
import locations from '../locations'
import servers from '../../servers'
import RemoveDialog from './removeDialog'
import Servers from '../Servers'

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// icons
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import NotesIcon from '@mui/icons-material/Notes';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import SaveAsOutlinedIcon from '@mui/icons-material/SaveAsOutlined';

import { useRecoilState, useRecoilValue } from 'recoil'
import {
  isSubscribedSelector,
  tokenState,
} from '#state'

const maxConfigs = 5

export default function StaticConfig(props) {

  console.log('StaticConfig render', props)
  
  const token = useRecoilValue(tokenState)
  const isSubscribed = useRecoilValue(isSubscribedSelector)

  const [configsList, setConfigsList] = useState([])
  const [selectedConfigIndex, setSelectedConfigIndex] = useState(null)
  const [refreshId, setRefreshId] = useState(0)

  useEffect(() => {
    const run = async () => {
      const result = await requests.getStaticConfigsList(token)
      if (!result.success) {
        console.log('getStaticConfigsList failed', result)
        if (result.error) {
          console.log('getStaticConfigsList error', result.error)
        }
      } else {
        const configs = result.configs.filter(el => {
          return !!el.configFmt
        })
        console.log('getStaticConfigsList', configs)
        if (configs.length) {
          setConfigsList(configs)
        }
        const newIndex = configs.length ? 0 : null
        if (selectedConfigIndex !== newIndex) {
          setSelectedConfigIndex(newIndex)
        }
      }
    }
    if (token) {
      run()
    }
  }, [token, refreshId])

  const [serversPlane, setServersPlane] = useState([])

  useEffect(() => {
    const plane = []
    servers.map(item => {
      const srvs = item.data.map(srv => {
        const country = locations.country[srv.countryCode]
        const city = locations.city[srv.id]
        const info = {
          ...srv,
          region: item.region,
          country,
          city,
        }
        plane.push(info)
        return info
      })
      return {
        region: item.region,
        data: srvs
      }
    })

    setServersPlane(plane)
    console.log('serversPlane', plane)
  }, [servers])

  if (!isSubscribed) {
    return null
  }

  return (
    <>
      <Typography variant="h5" sx={{
        my: 3,
        textAlign: 'center'
      }}>
        Wireguard Static Configs
      </Typography>

      <ConfigsList
        list={configsList}
        selected={selectedConfigIndex}
        select={setSelectedConfigIndex}
        serversPlane={serversPlane}
        token={token}
        setRefreshId={setRefreshId}
      />
    </>
  )
}


function ConfigsList(props) {
  const { list, select, selected,
    serversPlane, token, setRefreshId } = props

  const [showQR, setShowQR] = useState(true)
  const [createMode, setCreateMode] = useState(false)
  const qrRef = useRef(null)

  useEffect(() => {
    if (list && !list.length && !createMode) {
      setCreateMode(true)
    }
  }, [list, createMode])

  if (!list) {
    return (
      <Container>
        <Grid
          container
          justifyContent="space-evenly"
        >
          <Grid item xs={3}>
            <Skeleton variant="rounded" width={'100%'} height={'30vh'} />
          </Grid>
          <Grid
            container
            xs={8}
            direction="column"
            justifyContent="space-between"
          >
            <Grid item xs={2} justifyContent="center">
              <Skeleton variant="rounded" width={'100%'} height={'100%'} />
            </Grid>
            <Grid item xs={9} justifyContent="center" alignItems={'center'}>
              <Skeleton variant="rounded" width={'100%'} height={'100%'} />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    )
  }

  const activeConfig = list[selected]
  const activeConfigText = list[selected]?.configFmt || ''
  const fixedText = activeConfigText.replace(/\n\n/g, '\n')
  // const aid = list[selected].id
  // const locInfo = serversPlane[list[selected].id]
  // let name = aid
  // if (locInfo && locInfo.city && locInfo.country) {
  //   name = `${locInfo.city}, ${locInfo.country}`
  // }

  return (
    <div className='ConfigsListWrapper'>

      <Typography
        variant="body1"
        textAlign={'center'}
        sx={{ mb: 2 }}
      >
        {`Active configs: ${list.length} of ${maxConfigs}`}
      </Typography>

      <div className='ConfigsListContent'>
        {list.length ? <div className='ConfigsList'>
          <List dense={false}>
            {list.length && list.length < maxConfigs ?
              <Button
                fullWidth
                variant='outlined'
                color="inherit"
                startIcon={createMode ? <CancelOutlinedIcon /> : <PlaylistAddIcon />}
                className={'actionButton'}
                sx={{ marginBottom: '1vh' }}
                onClick={() => {
                  setCreateMode(!createMode)
                }}>
                {createMode ? 'Cancel' : 'New Config'}
              </Button> : null
            }
            {list.map((config, index) => {
              const selectedClass = index === selected ? 'configItem selected' : 'configItem'
              const locInfo = serversPlane.find(el => el.id === config.location)
              // console.log('srv', index, config, locInfo)
              let name = config.id
              let countryCode = 'un'
              if (locInfo && locInfo.city && locInfo.country) {
                name = `${locInfo.city}, ${locInfo.country}`
              }
              // console.log('srv location', config.location, locInfo)
              if (config?.location && locInfo && locInfo.countryCode) {
                countryCode = locInfo.countryCode
              }
              return (
                <ListItem
                  key={index}
                  className={selectedClass}
                  onClick={() => {
                    select(index)
                  }}
                >
                  <ListItemIcon>
                    <Flag country={countryCode} size={32} />
                  </ListItemIcon>
                  <ListItemText
                    primary={config.clientId}
                    secondary={name || config.location}
                  />
                </ListItem>
                // <div
                //   className={selectedClass}
                //   key={index}
                //   onClick={() => {
                //     select(index)
                //   }}>
                //   <div className='text-center'>{config.clientId}</div>
                //   <div className='text-center'>{name || config.location}</div>
                // </div>
              )

            })}
          </List>
        </div>
          : null}
        <div className='ConfigW'>
          {createMode ? <CreateConfig
            token={token}
            setCreateMode={setCreateMode}
            setRefreshId={setRefreshId}
            list={list}
          /> : <>
            <ConfigActionButtons
              text={fixedText}
              showQR={showQR}
              setShowQR={setShowQR}
              qrRef={qrRef}
              activeConfig={activeConfig}
              token={token}
              setRefreshId={setRefreshId}
            />
            <CurrentConfig
              text={fixedText}
              showQR={showQR}
              qrRef={qrRef}
            />
          </>}
        </div>
      </div>
    </div >
  )
}

function CreateConfig(props) {
  const { token, setCreateMode, setRefreshId, list } = props
  const [selectedServer, setSelectedServer] = useState(null)
  const [configName, setConfigName] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [showErrors, setShowErrors] = useState(false)
  const [isDefaultName, setIsDefaultName] = useState(true)
  const [isAwaiting, setIsAwaiting] = useState(false)

  const validate = () => {
    if (!selectedServer) {
      setShowErrors(true)
      setErrorMessage('Please select a server')
      return false
    } else if (!configName) {
      setErrorMessage('Config name is required')
      setShowErrors(true)
      return false
    }
    console.log('valid', configName, selectedServer)
    setShowErrors(false)
    return true
  }

  useEffect(() => {
    if (selectedServer && isDefaultName) {
      if (list && list.length) {
        const sameLocation = list.filter(el => el.location === selectedServer.id)
        console.log('set default ConfigName', selectedServer)
        setConfigName(`${selectedServer.enName}#${sameLocation.length + 1}`)
      } else {
        console.log('set default ConfigName', selectedServer)
        setConfigName(`${selectedServer.enName}#1`)
      }

    }
  }, [selectedServer, isDefaultName])

  useEffect(() => {
    if (showErrors) {
      validate()
    }
  }, [showErrors, selectedServer, configName])

  return (
    <div className='CreateConfig'>
      <Grid
        item
        container
        xs
        justifyContent='space-around'
        className='CreateConfigForm'
        sx={{
          padding: '0vh 2vw',
        }}
      >
        <Field
          value={configName}
          setValue={(value) => {
            if (value) {
              setIsDefaultName(false)
            } else {
              setIsDefaultName(true)
            }
            setConfigName(value)
          }}
          label={'Config Name'}
          placeholder={'Home Router Paris'}
          errorMessage={errorMessage}
          showErrors={showErrors}
          sx={{
            flex: '1 1 50%',
            marginRight: '1vw',
          }}
        />
        <Button
          disabled={!configName.length || !selectedServer}
          color="inherit"
          variant='outlined'
          startIcon={<SaveAsOutlinedIcon />}
          size='large'
          onClick={async () => {
            if (validate()) {
              console.log('create config')
              setIsAwaiting(true)
              const result = await requests.createStaticConfig(configName, selectedServer.id, token)
              setIsAwaiting(false)
              if (!result.success) {
                console.log('createStaticConfig failed', result)
              } else {
                setRefreshId(Math.random())
                setCreateMode(false)
              }
            }
          }}>
          {isAwaiting ? <CircularProgress color='inherit' size={20} /> : 'Create Config'}
        </Button>
      </Grid>
      <Servers
        servers={servers}
        selectedServer={selectedServer}
        setSelectedServer={setSelectedServer}
      />
    </div>
  )
}

function ConfigActionButtons(props) {
  const { text,
    showQR, setShowQR,
    qrRef, activeConfig,
    token, setRefreshId } = props

  const [deleteAwaiting, setDeleteAwaiting] = useState(false)
  const [showRemoveDialog, setShowRemoveDialog] = useState(false)

  if (!activeConfig) {
    return null
  }

  let QRAction = null
  const className = 'actionButton'
  if (showQR) {
    QRAction = (
      <Button
        variant='outlined'
        color="inherit"
        startIcon={<SaveIcon />}
        className={className}
        sx={{ mx: 1 }}
        onClick={() => {
          const anchor = document.createElement('a')
          const canvas = qrRef.current.children[0]
          anchor.href = canvas.toDataURL('image/png')
          anchor.download = `DingoVPN_WireGuard_${activeConfig.clientId}.png`;
          anchor.click();
        }}>
        Save QR
      </Button>
    )
  } else {
    QRAction = (
      <CopyToClipboard text={text}
        onCopy={() => { console.log('copied') }}>
        <Button
          variant='outlined'
          color="inherit"
          startIcon={<ContentCopyIcon />}
          sx={{ mx: 1 }}
          className={className}
        >
          Copy
        </Button>
      </CopyToClipboard>
    )
  }

  return (
    <Box className='ConfigsListButtons'>
      <Button
        variant='outlined'
        color="inherit"
        startIcon={!showQR ? <QrCode2Icon /> : <NotesIcon />}
        className={className}
        onClick={() => {
          setShowQR(!showQR)
        }}>
        {showQR ? 'Show Text' : 'Show QR'}
      </Button>

      {QRAction}

      <Button
        variant='outlined'
        color="error"
        startIcon={<DeleteIcon />}
        className={className}
        onClick={async () => {
          setShowRemoveDialog(true)
          // setDeleteAwaiting(true)
          // const result = await requests.deleteStaticConfig(activeConfig._id, token)
          // setDeleteAwaiting(false)
          // if (!result.success) {
          //   console.log('deleteStaticConfig failed', result)
          // } else {
          //   setRefreshId(Math.random())
          // }
        }}>
        {deleteAwaiting ? <CircularProgress color='inherit' size={20} /> : 'DELETE'}
      </Button>

      <RemoveDialog
        open={showRemoveDialog}
        setOpen={setShowRemoveDialog}
        onApprove={async () => {
          console.log('RemoveDialog approve')
          setShowRemoveDialog(false)
          setDeleteAwaiting(true)
          const result = await requests.deleteStaticConfig(activeConfig._id, token)
          setDeleteAwaiting(false)
          if (!result.success) {
            console.log('deleteStaticConfig failed', result)
          } else {
            setRefreshId(Math.random())
          }
        }}
        onCancel={() => {
          console.log('RemoveDialog cancel')
          setShowRemoveDialog(false)
        }}
      />
    </Box>
  )
}

function CurrentConfig(props) {
  const { text, showQR, qrRef } = props
  const theme = useTheme()

  if (!text) {
    return null
  }

  const QR = <div className='configQRCodeWrapper' ref={qrRef}>
    <QRCodeCanvas
      className='configQRCode'
      value={text}
      size={256}
      bgColor={'#ffffff'}
      fgColor={'#2a7dcb'}
      level={"M"}
      includeMargin={true}
      imageSettings={{
        src: "./qr_logo.png",
        x: 92,
        // y: 0,
        height: 48,
        width: 48,
        excavate: false,
      }}
    />
  </div>

  return (
    <div className='ConfigContent'>
      {showQR ? QR : null}

      {!showQR ? <textarea
        className='configFmt notranslate'
        style={{
          background: theme.palette.background.default,
          color: theme.palette.text.primary,
        }}
        readOnly
        spellCheck={false}
        name="configFmt"
        rows="10"
        cols="68"
        value={text}
      /> : null}
    </div>
  )
}

function Field(params) {
  const {
    value,
    setValue,
    label,
    type,
    placeholder,
    errorMessage,
    showErrors,
    sx,
  } = params
  return (
    <TextField
      fullWidth
      variant='outlined'
      type={type}
      value={value}
      onChange={event => setValue(event.target.value)}
      error={showErrors && !!errorMessage}
      label={label || placeholder}
      placeholder={placeholder}
      helperText={showErrors && errorMessage}
      sx={sx}
    />
  )
}
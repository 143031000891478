import React from 'react'
import { Container, Box, Stack, Typography, Link, Paper } from '@mui/material';

import windowsBadge from '../assets/badge-windows-version.png'
// import windows32Badge from '../assets/badge-windows-x86.png'
import macosBadge from '../assets/badge_macos.png'
// import macosUniversalBadge from '../assets/badge_macos_universal.png'
// import macosArmBadge from '../assets/badge_macos_applesilicon.png'
import appStoreBadge from '../assets/badge_ios.svg'
import googlePlayBadge from '../assets/google-play-badge.png'

export default function Download() {
  return (
    <>
      <Typography variant="h5" sx={{
        my: 3,
        textAlign: 'center'
      }}>
        Download DingoVPN
      </Typography>

      <Stack
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        spacing={1}
        sx={{ flexWrap: 'wrap' }}
      >

        <a
          href='https://apps.apple.com/us/app/dingovpn-fast-secure/id1634385996'
          target='_blank'
          rel='noopener noreferrer'
        >
          <img
            src={appStoreBadge}
            alt=''
            style={{ height: '72px' }}
          />
        </a>

        <a
          href='https://play.google.com/store/apps/details?id=com.dingovpn'
          target='_blank'
          rel='noopener noreferrer'
        >
          <img
            src={googlePlayBadge}
            alt=''
            style={{ height: '72px' }}
          />
        </a>
        <a
          target='_blank'
          rel='noopener noreferrer'
          href='https://dingovpn.com/instructions.html'
        >
          <img
            src={windowsBadge}
            alt=''
            style={{ height: '72px' }}
          />
        </a>

        <a
          target='_blank'
          rel='noopener noreferrer'
          href='https://dingovpn.com/macosinstructions.html'
        >
          <img
            src={macosBadge}
            alt=''
            style={{ height: '72px' }}
          />
        </a>
      </Stack>
    </>
  )
}
